import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Select, Spin, Table } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import moment from "moment";
import logo from "../../assets/images/logo.png";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import "../ProducList/productList.css";
import { TabTitle } from "../../utils/GeneralFunction.js";
import { useDownloadExcel } from "react-export-table-to-excel";
import { getDropshipSummary } from "../../redux/actions/dropshipSummaryReports.action.js";

const DropshipSummary = ({ token }) => {
  TabTitle("DropShip Summary")
  const { ecommerceDaily } = useSelector((state) => state);
  const userName = ReadCookie("name");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  var date = moment();
  const [dateTypeId, setDateTypeId] = useState("lw");
  const [monthId, setMonthId] = useState(date.format("MM"));
  const [yearId, setYearId] = useState(date.format("Y"));
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const { dropshipSummary } = useSelector((state) => state);

  const { onDownload: onDownloadReport } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Dropship Summary",
    sheet: "Sheet 1",
  });

  const groupedData = dropshipSummary?.payload?.data.reduce((acc, item) => {
    if (!acc[item.gender_name]) {
      acc[item.gender_name] = [];
    }
    acc[item.gender_name].push(item);
    return acc;
  }, {});


  useEffect(() => {
    let year = yearId;
    let currentYearStartDate = moment().startOf("year").format("MM/DD/YYYY");
    let currentYearEndDate = moment().endOf("year").format("MM/DD/YYYY");
    if (dateTypeId === "mtd") {
      currentYearStartDate = moment().format("MM") + "/" + "01" + "/" + year;
      currentYearEndDate = moment().format("MM") + "/" + moment().format("DD") + "/" + year;
    } else if (dateTypeId === "lw") {
      currentYearStartDate = moment().subtract(7, 'days').format("MM/DD/YYYY");
      currentYearEndDate = moment().format("MM/DD/YYYY");
    } else if (dateTypeId === "stds") { // January to June only
      currentYearStartDate = moment().startOf('year').format("MM/DD/YYYY");
      currentYearEndDate = moment().month(5).date(30).format("MM/DD/YYYY");
    } else if (dateTypeId === "stdf") {
      currentYearStartDate = moment().startOf('year').add(6, 'months').startOf('month').format("MM/DD/YYYY");
      currentYearEndDate = moment().endOf('year').format("MM/DD/YYYY");
    } else if (dateTypeId === "ytd") {
      currentYearStartDate = moment().startOf('year').format("MM/DD/YYYY");
      currentYearEndDate = moment().endOf('year').format("MM/DD/YYYY");
    }
    const newState = {
      cy_start_date: currentYearStartDate,
      cy_end_date: currentYearEndDate,
    };
    setFormState(newState);
  }, [dateTypeId, monthId, yearId]);

  useEffect(() => {
    if (dateTypeId === "mtd") {
      setMonthId(moment().format("MM"));
    }
  }, [dateTypeId]);

  console.log({ formState, dateTypeId })

  const onFinish = () => {
    setLoading(true);
    dispatch(getDropshipSummary(token, formState)).then((res) => {
      if (res.type === "GET_DROPSHIP_SUMMARY_REPORTS_SUCCESS") {
        setLoading(false);
      }
    });
  };

  const handlePrint = () => {
    window.print();
  };

  const columns = [
    {
      title: "#",
      className: "b_bottom",
      render: (text, record, index) => {
        return index + 1
      },
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      className: " b_left b_bottom",
      render: (text, record) => {
        return (
          <>
            {text}
          </>
        );
      },
      sorter: (a, b) => {
        return a.customer_name.localeCompare(b.customer_name)
      },
    },
    {
      title: "SHP U",
      dataIndex: "shipped_qty",
      key: "shipped_qty",
      className: " b_left b_bottom",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {text}
          </>
        );
      },
      sorter: (a, b) => {
        return a.shipped_qty - b.shipped_qty
      },
    },
    {
      title: "SHP $",
      dataIndex: "shipped_amount",
      key: "shipped_amount",
      className: " b_left b_bottom",
      align: "right",
      render: (text, record) => {
        return (
          <>
            $ {text}
          </>
        );
      },
      sorter: (a, b) => {
        return a.shipped_amount - b.shipped_amount
      },
    },
    {
      title: "RTRN U",
      dataIndex: "returned_qty",
      key: "returned_qty",
      className: " b_left b_bottom",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {text}
          </>
        );
      },
      sorter: (a, b) => {
        return a.returned_qty - b.returned_qty
      },
    },
    {
      title: "RTRN $",
      dataIndex: "returned_amount",
      key: "returned_amount",
      className: " b_left b_bottom",
      align: "right",
      render: (text, record) => {
        return (
          <>
            $ {text}
          </>
        );
      },
      sorter: (a, b) => {
        return a.returned_amount - b.returned_amount
      },
    },
    {
      title: "NET U",
      dataIndex: "net_qty",
      key: "net_qty",
      className: " b_left b_bottom",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {text}
          </>
        );
      },
      sorter: (a, b) => {
        return a.net_qty - b.net_qty
      },
    },
    {
      title: "NET $",
      dataIndex: "net_amount",
      key: "net_amount",
      className: " b_left b_bottom",
      align: "right",
      render: (text, record) => {
        return (
          <>
            $ {text}
          </>
        );
      },
      sorter: (a, b) => {
        return a.net_amount - b.net_amount
      },
    },
    {
      title: "AUC",
      dataIndex: "auc",
      key: "auc",
      className: " b_left b_bottom",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {text}
          </>
        );
      },
      sorter: (a, b) => {
        return a.auc - b.auc
      },
    },
    {
      title: "RTRN %",
      dataIndex: "ret_rate_per",
      key: "ret_rate_per",
      className: " b_left b_bottom",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {text} %
          </>
        );
      },
      sorter: (a, b) => {
        return a.ret_rate_per - b.ret_rate_per
      },
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class">
              <div className="row justify-content-between pb-0">
                <div className="col-3 d-flex align-items-start  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>

                <div className="col-3 box-space right-content-header text-right">
                  <div className=" d-flex justify-content-end">
                    <div className=" d-flex justify-content-end printing_class">
                      <div className="print_icon printing_class">
                        <i
                          id="Image1"
                          title="Print"
                          alt="Print"
                          className="fa-solid fa-print printing_class"
                          onClick={handlePrint}
                        ></i>
                      </div>
                      <div className="print_icon printing_class">
                        {dropshipSummary?.payload?.data ? (
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            onClick={onDownloadReport}
                            style={{
                              borderWidth: "0px",
                              height: "14px",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            style={{ borderWidth: "0px", height: "14px" }}
                          />
                        )}
                      </div>
                    </div>
                    <span style={{ fontWeight: "bolder" }}>
                      Dropship Summary
                      <br />
                      Rpt # 006
                      <br />
                      Login as:
                      <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {" "}
                        {userName}
                      </span>

                    </span>
                  </div>
                </div>
              </div>
              <div className="row pb-2">
                <div className="col">
                  <Form
                    layout="horizontal"
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                  >
                    <div className="main_form row">
                      <div className=" col-4">
                        <div className=" d-flex flex-row align-items-baseline">
                          <label htmlFor="gender_id" className="labels width30">
                            Condition:
                          </label>
                          <div style={{ marginBottom: "-2px" }} className=" w-100">
                            <Form.Item
                              name="dateTypeId"
                              className="formFullWidth"
                            >
                              <Select
                                size="small"
                                showSearch
                                optionFilterProp="children"
                                className={"select_Inputs"}
                                defaultValue={dateTypeId}
                                value={dateTypeId}
                                onChange={(a) => {
                                  setDateTypeId(a);
                                  setYearId(date.format("Y"));
                                }}
                              >
                                <Option key={"lw"} value={"lw"}>
                                  Last Week
                                </Option>
                                <Option key={"mtd"} value={"mtd"}>
                                  Month To Date
                                </Option>
                                <Option key={"stds"} value={"stds"}>
                                  Season To Date - Spring
                                </Option>
                                <Option key={"stdf"} value={"stdf"}>
                                  Season To Date - Fall
                                </Option>
                                <Option key={"ytd"} value={"ytd"}>
                                  Year To Date
                                </Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className=" col-4">
                        {/* <div className=" d-flex flex-row align-items-baseline">
                          <label className="labels width30">Month:</label>
                          <div style={{ marginBottom: "2px" }} className=" w-100">
                            <DatePicker
                              size="small"
                              className="filter-fields full_date"
                              value={moment(monthId)}
                              // value={moment(dateTypeId === "mtd" ? moment() : monthId)}
                              disabled={dateTypeId === "mtd"}
                              onChange={(e) =>
                                setMonthId(moment(e).format("MM"))
                              }
                              picker="month"
                              format={"MM"}
                              mode="month"
                            />
                          </div>
                        </div> */}
                        <div style={{ marginTop: "3px", display: "flex", justifyContent: "left" }} >
                          <Button type="primary" htmlType="submit" className='submit-btn-btm m-0' disabled={loading}>
                            Search
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table_content"
        style={{ width: '60%' }}
        ref={tableRef} >
        <div>
          {loading ? (
            <div className="d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin />
            </div>
          ) : dropshipSummary?.payload?.data.length ? (
            // Loop through each state group and render a table
            Object.entries(groupedData).map(([region, data]) => (
              <div key={region} >
                <h3 style={{ borderBottom: "1px solid black", paddingBottom: "1px", display: "inline-block" }}>{region}</h3>
                <Table
                  style={{ fontSize: '10.3px' }}
                  className="test04 mt-3 sales_target_report_table sales_report_res_table sales_target_report_table_dashboard speciality_state"
                  size="small"
                  pagination={false}
                  summary={pageData => {
                    let shipQty = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.shipped_qty)
                    }, 0);
                    let shipAmount = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.shipped_amount)
                    }, 0);
                    let returnQty = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.returned_qty)
                    }, 0);
                    let returnAmount = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.returned_amount)
                    }, 0);
                    let netQty = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.net_qty)
                    }, 0);
                    let netAmount = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.net_amount)
                    }, 0);
                    let auc = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.auc)
                    }, 0);
                    let rtrnPerQty = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.ret_rate_per)
                    }, 0);
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right" }}>Total</p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(shipQty.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              $ {numeral(shipAmount.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(returnQty.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              $ {numeral(returnAmount.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(netQty.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              $ {numeral(netAmount.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(auc.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(rtrnPerQty.toLocaleString("en-US")).format("0,00")} %
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                  bordered
                  columns={columns}
                  scroll={{ x: 'max-content' }}
                  dataSource={data}
                />
              </div>
            ))
          ) : (
            <div
              style={{
                backgroundColor: '#CCCCCC',
                borderWidth: '1pt',
                borderStyle: 'Dashed',
                fontWeight: 'bold',
                height: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              No record(s)
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DropshipSummary;

