import {
    GET_PRODUCTION_FINISHED_REQUEST,
    GET_PRODUCTION_FINISHED_SUCCESS,
    GET_PRODUCTION_FINISHED_ERROR,
  } from "./types";
  import axios from "axios";
  import { BASEURL } from "../../constant/config.js";
  
  export const productionFinish = (token) => (dispatch) => {
    dispatch({ type: GET_PRODUCTION_FINISHED_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}getAll/productionfinishedreport`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: {},
    })
      .then((response) => {
        return dispatch({
          type: GET_PRODUCTION_FINISHED_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PRODUCTION_FINISHED_ERROR,
          payload: [],
        });
      });
  };
  