import {
  GET_PAYMENT_RECEIPT_REQUEST,
GET_PAYMENT_RECEIPT_SUCCESS,
GET_PAYMENT_RECEIPT_ERROR
  } from "./types";
  import axios from "axios";
  import { BASEURL } from "../../constant/config.js";
  import moment from "moment";

  export const getPaymentReceipt = (token, values) => (dispatch) => { 
   
    dispatch({ type: GET_PAYMENT_RECEIPT_REQUEST });
    const newData = {
            from_date: moment(values.from_date).format("MM-DD-YYYY"),
            to_date: moment(values.to_date).format("MM-DD-YYYY"),
            customer_id: (values.customer === undefined || values.customer === ""|| values.customer === "All") ? null : values.customer,
            payment_type_id: values.payment_type_id === "All" || values.payment_type_id === undefined ? null : values.payment_type_id,
            payment_term_id : values.terms === "All" || values.terms === undefined ? null : values.terms,
    };
    return axios({
      method: "POST",
      url: `${BASEURL}getAll/PaymentReceiptReport`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: newData,
    })
      .then((response) => {
        return dispatch({
          type: GET_PAYMENT_RECEIPT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PAYMENT_RECEIPT_ERROR,
          payload: [],
        });
      });
  };
  