import {
  GET_DROPSHIP_SUMMARY_REPORTS_REQUEST,
  GET_DROPSHIP_SUMMARY_REPORTS_SUCCESS,
  GET_DROPSHIP_SUMMARY_REPORTS_ERROR,
  GET_DROPSHIP_SKU_REPORTS_REQUEST,
  GET_DROPSHIP_SKU_REPORTS_SUCCESS,
  GET_DROPSHIP_SKU_REPORTS_ERROR,
  GET_DROPSHIP_TOTAL_REPORTS_REQUEST,
  GET_DROPSHIP_TOTAL_REPORTS_SUCCESS,
  GET_DROPSHIP_TOTAL_REPORTS_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

export const getDropshipSummary = (token, values) => (dispatch) => {
  dispatch({ type: GET_DROPSHIP_SUMMARY_REPORTS_REQUEST });
  const newData = {
    from_date: values.currentYearStartDate ? values.currentYearStartDate : values.cy_start_date,
    to_date: values.currentYearEndDate ? values.currentYearEndDate : values.cy_end_date
  };
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/DropShipCustomerSummary`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_DROPSHIP_SUMMARY_REPORTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_DROPSHIP_SUMMARY_REPORTS_ERROR,
        payload: [],
      });
    });
};


export const getDropshipSKU = (token,formState, values) => (dispatch) => {
  console.log("123",formState)
  dispatch({ type: GET_DROPSHIP_SKU_REPORTS_REQUEST });
  const newData = {
    from_date: formState.currentYearStartDate ? formState.currentYearStartDate : formState.cy_start_date,
    to_date: formState.currentYearEndDate ? formState.currentYearEndDate : formState.cy_end_date,
    customer_id: (values.customer_id === "All" || values.customer_id === undefined)  ? null : values.customer_id,
    gender_id: (values.gender_id === "All" || values.gender_id === undefined)  ? null : values.gender_id,
  };
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/DropShipBySku`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_DROPSHIP_SKU_REPORTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_DROPSHIP_SKU_REPORTS_ERROR,
        payload: [],
      });
    });
};


export const getDropshipTotal = (token ,formState, values) => (dispatch) => {
  dispatch({ type: GET_DROPSHIP_TOTAL_REPORTS_REQUEST });
  const newData = {
   from_date: formState.currentYearStartDate ? formState.currentYearStartDate : formState.cy_start_date,
    to_date: formState.currentYearEndDate ? formState.currentYearEndDate : formState.cy_end_date,
    gender_id: (values.gender_id === "All" || values.gender_id === undefined)  ? null : values.gender_id,
  };
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/DropShipTotal`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_DROPSHIP_TOTAL_REPORTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_DROPSHIP_TOTAL_REPORTS_ERROR,
        payload: [],
      });
    });
};