import { UPDATE_PRODUCT_IMAGE_URL_ERROR, UPDATE_PRODUCT_IMAGE_URL_REQUEST, UPDATE_PRODUCT_IMAGE_URL_SUCCESS } from "./types"
import { BASEURL } from "../../constant/config.js"
const axios = require("axios");

export const updateProductImage = (token, values) => dispatch => {
    dispatch({ type: UPDATE_PRODUCT_IMAGE_URL_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/UpdateProductImage/${123}`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
        data: { sku: values.sku, image_url: values.url }
    })
        .then(response => {
            return dispatch({ type: UPDATE_PRODUCT_IMAGE_URL_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_PRODUCT_IMAGE_URL_ERROR,
                payload: error.response,
            });
        })
}