import React, { useCallback, useEffect, useState } from "react";
import { Spin, Select, DatePicker, Checkbox, Form, Input, Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getGenderCategory, getGenderCategoryChange } from "../../redux/actions/genderCategory.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getProductClass } from "../../redux/actions/productClass.action";
import { getFitCategory } from "../../redux/actions/fitCategory.action";
import { getSeason } from "../../redux/actions/season.action";
import { getGender } from "../../redux/actions/gender.action";
import { getSize } from "../../redux/actions/size.action";
import { getSku } from "../../redux/actions/sku.action"
import { ReadCookie } from "../../utils/readCookie";
import { getStyle, getStyleChange } from "../../redux/actions/style";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import { Excel } from "antd-table-saveas-excel";
import Loader from "react-loader-spinner";
import { getWashName } from "../../redux/actions/wash.action";
import { useRef } from "react";
import { DownloadTableExcel, useDownloadExcel } from "react-export-table-to-excel";
import { productionReport } from "../../redux/actions/productionReport.action";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const { Option } = Select;


const ProductionReport = ({ token }) => {
  TabTitle("Production")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    style,
    gender,
    season,
    omsStockSummary,
    genderCategoryChange,
    styleChange,
    Sku,
    wash,
    production
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [productionList, setProductionList] = useState([]);
  const [excelData, setExcelData] = useState([])
  const [isGenerate, setIsGenerate] = useState("All");
  const [isSKU, setIsSKU] = useState("All");
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let query_sku = query.get("sku");


  useEffect(() => {
    if (query_sku) {
      const values = {
        sku: query_sku
      }
      form.setFieldsValue({
        sku: values.sku,
      });
      setLoading(true)
      dispatch(productionReport(token, values)).then((res) => {
        setLoading(false)
        setProductionList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }
  }, [query_sku])

  const onFinish = async (e) => {
    await form.setFieldsValue({
      gender_id: isGenerate,
      sku: isSKU
    });
    setLoading(true)
    await form.validateFields().then((data) => {

      dispatch(productionReport(token, data, isGenerate, isSKU)).then((res => {
        setLoading(false)
        setProductionList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      }));
    });
  };

  useEffect(() => {
    dispatch(getStyle(token, menuId));
    dispatch(getSeason(token, menuId));
    dispatch(getProductClass(token, menuId));
    dispatch(getSize(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getWashName(token, menuId));
    dispatch(getFitCategory(token, menuId));
  }, [dispatch, menuId, token]);

  const handlePrint = () => {
    window.print();
  };

  let sizeChartRendering = form.getFieldValue("gender_id")
  let sizeChartRenderingSKU = form.getFieldValue("sku")


  const colums = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "SKU",
      dataIndex: "item_code",
      key: "item_code",
      // width: 80,
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
      key: "inseam",
      // width: 50,
    },
    {
      title: "Style",
      dataIndex: "style_name",
      key: "style_name",
    },
    {
      title: "Wash",
      dataIndex: "wash_name",
      key: "wash_name",
    },
    {
      title: "Season",
      dataIndex: "new_season",
      key: "new_season",
      // width: 60,
    },
    {
      title: "Status",
      dataIndex: "product_status",
      key: "product_status",
      // width: 80,
    },
    {
      title: "Bulk Order #",
      dataIndex: "bulk_order_no",
      key: "bulk_order_no",
      // width: 70,
    },
    {
      title: "Bulk Order Date",
      dataIndex: "po_date",
      key: "po_date",
      // width: 100,
      render: (text, record) => {
        return <>{moment(record.po_date).format("MM-DD-YY")}</>
      },
    },
    sizeChartRendering === 13 || (sizeChartRenderingSKU !== "All" && production?.payload?.data[0]?.gender_name === "Kids") ? (
      {
        children: [
          {
            title: "T-Jacket",
            dataIndex: "stage",
            key: "stage",
            width: 75,
            children: [
              {
                title: "Shirt/RTW-P",
                dataIndex: "stage",
                key: "stage",
                width: 75,
                children: [
                  {
                    title: "Shirt/RTW-R",
                    dataIndex: "stage",
                    key: "stage",
                    width: 75,
                    children: [
                      {
                        title: "Y-Adult",
                        dataIndex: "stage",
                        key: "stage",
                        width: 75,
                        children: [
                          {
                            title: "Kids",
                            dataIndex: "stage",
                            key: "stage",
                            width: 75,
                          }
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: '"2/3"',
            align: "right",
            dataIndex: "qty_1",
            key: "qty_1",
            width: 40,
            children: [
              {
                title: "1XL",
                dataIndex: "qty_1",
                key: "qty_1",
                width: 40,
                align: "right",
                children: [
                  {
                    title: "XS",
                    dataIndex: "qty_1",
                    key: "qty_1",
                    width: 40,
                    align: "right",
                    children: [
                      {
                        title: "00",
                        dataIndex: "qty_1",
                        key: "qty_1",
                        width: 40,
                        align: "right",
                        children: [
                          {
                            title: "2",
                            dataIndex: "qty_1",
                            key: "qty_1",
                            width: 40,
                            align: "right",
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: '"4/5"',
            align: "right",
            dataIndex: "qty_2",
            key: "qty_2",
            width: 50,
            children: [
              {
                title: "2XL",
                align: "right",
                dataIndex: "qty_2",
                key: "qty_2",
                width: 55,
                children: [
                  {
                    title: "S",
                    align: "right",
                    dataIndex: "qty_2",
                    key: "qty_2",
                    width: 55,
                    children: [
                      {
                        title: "0",
                        align: "right",
                        dataIndex: "qty_2",
                        key: "qty_2",
                        width: 55,
                        children: [
                          {
                            title: "3",
                            align: "right",
                            dataIndex: "qty_2",
                            key: "qty_2",
                            width: 50,
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: '"6/6X"',
            dataIndex: "qty_3",
            key: "qty_3",
            align: "right",
            width: 60,
            children: [
              {
                title: "3XL",
                dataIndex: "qty_3",
                key: "qty_3",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "M",
                    dataIndex: "qty_3",
                    key: "qty_3",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "1",
                        dataIndex: "qty_3",
                        key: "qty_3",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "4",
                            dataIndex: "qty_3",
                            key: "qty_3",
                            width: 50,
                            align: "right",
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_4",
            key: "qty_4",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_4",
                key: "qty_4",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "L",
                    dataIndex: "qty_4",
                    key: "qty_4",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "2",
                        dataIndex: "qty_4",
                        key: "qty_4",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "5",
                            dataIndex: "qty_4",
                            key: "qty_4",
                            width: 50,
                            align: "right",
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_5",
            key: "qty_5",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_5",
                key: "qty_5",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "XL",
                    dataIndex: "qty_5",
                    key: "qty_5",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "3",
                        dataIndex: "qty_5",
                        key: "qty_5",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "6",
                            dataIndex: "qty_5",
                            key: "qty_5",
                            width: 50,
                            align: "right",
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_6",
            key: "qty_6",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_6",
                key: "qty_6",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "XXL",
                    dataIndex: "qty_6",
                    key: "qty_6",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "4",
                        dataIndex: "qty_6",
                        key: "qty_6",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "6x",
                            dataIndex: "qty_6",
                            key: "qty_6",
                            width: 50,
                            align: "right",
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_7",
            key: "qty_7",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_7",
                key: "qty_7",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_7",
                    key: "qty_7",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "5",
                        dataIndex: "qty_7",
                        key: "qty_7",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "7",
                            dataIndex: "qty_7",
                            key: "qty_7",
                            width: 50,
                            align: "right",
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_8",
            key: "qty_8",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_8",
                key: "qty_8",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_8",
                    key: "qty_8",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "6",
                        dataIndex: "qty_8",
                        key: "qty_8",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "8",
                            dataIndex: "qty_8",
                            key: "qty_8",
                            width: 50,
                            align: "right",
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_9",
            key: "qty_9",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_9",
                key: "qty_9",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_9",
                    key: "qty_9",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "7",
                        dataIndex: "qty_9",
                        key: "qty_9",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "10",
                            dataIndex: "qty_9",
                            key: "qty_9",
                            width: 50,
                            align: "right",
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_10",
            key: "qty_10",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_10",
                key: "qty_10",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_10",
                    key: "qty_10",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "8",
                        dataIndex: "qty_10",
                        key: "qty_10",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "12",
                            dataIndex: "qty_10",
                            key: "qty_10",
                            width: 50,
                            align: "right",
                          },
                        ],
                      },
                    ],
                  }]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_11",
            key: "qty_11",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_11",
                key: "qty_11",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_11",
                    key: "qty_11",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "9",
                        dataIndex: "qty_11",
                        key: "qty_11",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "14",
                            dataIndex: "qty_11",
                            key: "qty_11",
                            width: 50,
                            align: "right",
                          },
                        ],
                      },
                    ],
                  }]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_12",
            key: "qty_12",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_12",
                key: "qty_12",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_12",
                    key: "qty_12",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "10",
                        dataIndex: "qty_12",
                        key: "qty_12",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "16",
                            dataIndex: "qty_12",
                            key: "qty_12",
                            width: 50,
                            align: "right",
                          },
                        ],
                      },
                    ],
                  }]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_13",
            key: "qty_13",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_13",
                key: "qty_13",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_13",
                    key: "qty_13",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_13",
                        key: "qty_13",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "18",
                            dataIndex: "qty_13",
                            key: "qty_13",
                            width: 50,
                            align: "right",
                          },
                        ],
                      },
                    ],
                  }]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_14",
            key: "qty_14",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_14",
                key: "qty_14",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_14",
                    key: "qty_14",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_14",
                        key: "qty_14",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "20",
                            dataIndex: "qty_14",
                            key: "qty_14",
                            width: 50,
                            align: "right",
                          },
                        ],
                      }
                    ]
                  },]
              }
            ],
          },
          {
            title: "-",
            dataIndex: "qty_15",
            key: "qty_15",
            width: 50,
            align: "right",
            children: [
              {
                title: "-",
                dataIndex: "qty_15",
                key: "qty_15",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_15",
                    key: "qty_15",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_15",
                        key: "qty_15",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "-",
                            dataIndex: "qty_15",
                            key: "qty_15",
                            width: 50,
                            align: "right",
                          },
                        ],
                      },
                    ],
                  }]
              }
            ]
          },
          {
            title: "-",
            dataIndex: "qty_16",
            key: "qty_16",
            width: 50,
            align: "right",
            className: "Border-Right-table border-z-index-1",
            children: [
              {
                title: "-",
                dataIndex: "qty_16",
                key: "qty_16",
                width: 50,
                align: "right",
                className: "Border-Right-table border-z-index-1",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_16",
                    key: "qty_16",
                    width: 50,
                    align: "right",
                    className: "Border-Right-table border-z-index-1",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_16",
                        key: "qty_16",
                        width: 50,
                        align: "right",
                        className: "Border-Right-table border-z-index-1",
                        children: [
                          {
                            title: "-",
                            dataIndex: "qty_16",
                            key: "qty_16",
                            width: 50,
                            align: "right",
                            className: "Border-Right-table border-z-index-1",
                          },
                        ],
                      },
                    ],
                  }
                ]
              }
            ]
          },
        ],
      }
    )
      : sizeChartRendering === 7 || (sizeChartRenderingSKU !== "All" && production?.payload?.data[0]?.gender_name === "Women") ? (
        // // for Women
        {
          children: [
            {
              title: 'Shirt/RTW-P',
              dataIndex: "stage",
              key: "stage",
              width: 75,
              children: [
                {
                  title: 'Shirt/RTW-R',
                  dataIndex: "stage",
                  key: "stage",
                  width: 75,
                  children: [
                    {
                      title: "W-Plus",
                      dataIndex: "stage",
                      key: "stage",
                      width: 75,
                      children: [
                        {
                          title: "Women",
                          dataIndex: "stage",
                          key: "stage",
                          width: 75,
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: '1XL',
              align: "right",
              dataIndex: "qty_1",
              key: "qty_1",
              width: 40,
              children: [
                {
                  title: "XS",
                  dataIndex: "qty_1",
                  key: "qty_1",
                  width: 40,
                  align: "right",
                  children: [
                    {
                      title: "10W",
                      dataIndex: "qty_1",
                      key: "qty_1",
                      width: 40,
                      align: "right",
                      children: [
                        {
                          title: "23",
                          dataIndex: "qty_1",
                          key: "qty_1",
                          width: 40,
                          align: "right",
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: '2XL',
              align: "right",
              dataIndex: "qty_2",
              key: "qty_2",
              width: 50,
              children: [
                {
                  title: "S",
                  align: "right",
                  dataIndex: "qty_2",
                  key: "qty_2",
                  width: 55,
                  children: [
                    {
                      title: "12W",
                      align: "right",
                      dataIndex: "qty_2",
                      key: "qty_2",
                      width: 55,
                      children: [
                        {
                          title: "24",
                          align: "right",
                          dataIndex: "qty_2",
                          key: "qty_2",
                          width: 55,
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: '3XL',
              dataIndex: "qty_3",
              key: "qty_3",
              align: "right",
              width: 60,
              children: [
                {
                  title: "M",
                  dataIndex: "qty_3",
                  key: "qty_3",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "14W",
                      dataIndex: "qty_3",
                      key: "qty_3",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "25",
                          dataIndex: "qty_3",
                          key: "qty_3",
                          width: 50,
                          align: "right",
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_4",
              key: "qty_4",
              width: 50,
              align: "right",
              children: [
                {
                  title: "L",
                  dataIndex: "qty_4",
                  key: "qty_4",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "16W",
                      dataIndex: "qty_4",
                      key: "qty_4",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "26",
                          dataIndex: "qty_4",
                          key: "qty_4",
                          width: 50,
                          align: "right",
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_5",
              key: "qty_5",
              width: 50,
              align: "right",
              children: [
                {
                  title: "XL",
                  dataIndex: "qty_5",
                  key: "qty_5",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "18W",
                      dataIndex: "qty_5",
                      key: "qty_5",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "27",
                          dataIndex: "qty_5",
                          key: "qty_5",
                          width: 50,
                          align: "right",
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_6",
              key: "qty_6",
              width: 50,
              align: "right",
              children: [
                {
                  title: "XXL",
                  dataIndex: "qty_6",
                  key: "qty_6",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "28W",
                      dataIndex: "qty_6",
                      key: "qty_6",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "28",
                          dataIndex: "qty_6",
                          key: "qty_6",
                          width: 50,
                          align: "right",
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_7",
              key: "qty_7",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_7",
                  key: "qty_7",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "22W",
                      dataIndex: "qty_7",
                      key: "qty_7",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "29",
                          dataIndex: "qty_7",
                          key: "qty_7",
                          width: 50,
                          align: "right",
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_8",
              key: "qty_8",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_8",
                  key: "qty_8",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "24W",
                      dataIndex: "qty_8",
                      key: "qty_8",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "30",
                          dataIndex: "qty_8",
                          key: "qty_8",
                          width: 50,
                          align: "right",
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_9",
              key: "qty_9",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_9",
                  key: "qty_9",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "26W",
                      dataIndex: "qty_9",
                      key: "qty_9",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "31",
                          dataIndex: "qty_9",
                          key: "qty_9",
                          width: 50,
                          align: "right",
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_10",
              key: "qty_10",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_10",
                  key: "qty_10",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "28W",
                      dataIndex: "qty_10",
                      key: "qty_10",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "32",
                          dataIndex: "qty_10",
                          key: "qty_10",
                          width: 50,
                          align: "right",
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_11",
              key: "qty_11",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_11",
                  key: "qty_11",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "30W",
                      dataIndex: "qty_11",
                      key: "qty_11",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "33",
                          dataIndex: "qty_11",
                          key: "qty_11",
                          width: 50,
                          align: "right",
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_12",
              key: "qty_12",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_12",
                  key: "qty_12",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "32W",
                      dataIndex: "qty_12",
                      key: "qty_12",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "34",
                          dataIndex: "qty_12",
                          key: "qty_12",
                          width: 50,
                          align: "right",
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_13",
              key: "qty_13",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_13",
                  key: "qty_13",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "-",
                      dataIndex: "qty_13",
                      key: "qty_13",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "35",
                          dataIndex: "qty_13",
                          key: "qty_13",
                          width: 50,
                          align: "right",
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_14",
              key: "qty_14",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_14",
                  key: "qty_14",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "-",
                      dataIndex: "qty_14",
                      key: "qty_14",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "36",
                          dataIndex: "qty_14",
                          key: "qty_14",
                          width: 50,
                          align: "right",
                        }
                      ]
                    }
                  ]
                },
              ],
            },
            {
              title: "-",
              dataIndex: "qty_15",
              key: "qty_15",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_15",
                  key: "qty_15",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "-",
                      dataIndex: "qty_15",
                      key: "qty_15",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "37",
                          dataIndex: "qty_15",
                          key: "qty_15",
                          width: 50,
                          align: "right",
                        },
                      ],
                    }
                  ]
                }
              ]
            },
            {
              title: "-",
              dataIndex: "qty_16",
              key: "qty_16",
              width: 50,
              align: "right",
              className: "Border-Right-table border-z-index-1",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_16",
                  key: "qty_16",
                  width: 50,
                  align: "right",
                  className: "Border-Right-table border-z-index-1",
                  children: [
                    {
                      title: "-",
                      dataIndex: "qty_16",
                      key: "qty_16",
                      width: 50,
                      align: "right",
                      className: "Border-Right-table border-z-index-1",
                      children: [
                        {
                          title: "38",
                          dataIndex: "qty_16",
                          key: "qty_16",
                          width: 50,
                          align: "right",
                          className: "Border-Right-table border-z-index-1",
                        },
                      ],
                    }
                  ]
                }
              ]
            },
          ],
        }
      )
        : sizeChartRendering === 9 || (sizeChartRenderingSKU !== "All" && production?.payload?.data[0]?.gender_name === "Men") ? (
  
          // for Men
          {
            children: [
              {
                title: "Shirt/RTW-P",
                dataIndex: "stage",
                key: "stage",
                width: 75,
                children: [
                  {
                    title: "Shirt/RTW-R",
                    dataIndex: "stage",
                    key: "stage",
                    width: 75,
                    children: [
                      {
                        title: "Men",
                        dataIndex: "stage",
                        key: "stage",
                        width: 75,
                      }
                    ]
                  }
                ]
              },
              {
                title: '1XL',
                align: "right",
                dataIndex: "qty_1",
                key: "qty_1",
                width: 40,
                children: [
                  {
                    title: "XS",
                    dataIndex: "qty_1",
                    key: "qty_1",
                    width: 40,
                    align: "right",
                    children: [
                      {
                        title: "27",
                        dataIndex: "qty_1",
                        key: "qty_1",
                        width: 40,
                        align: "right",
                      }
                    ]
                  }]
              },
              {
                title: '2XL',
                align: "right",
                dataIndex: "qty_2",
                key: "qty_2",
                width: 50,
                children: [
                  {
                    title: "S",
                    align: "right",
                    dataIndex: "qty_2",
                    key: "qty_2",
                    width: 55,
                    children: [
                      {
                        title: "28",
                        align: "right",
                        dataIndex: "qty_2",
                        key: "qty_2",
                        width: 55,
                      }
                    ]
                  }]
              },
              {
                title: '3XL',
                dataIndex: "qty_3",
                key: "qty_3",
                align: "right",
                width: 60,
                children: [
                  {
                    title: "M",
                    dataIndex: "qty_3",
                    key: "qty_3",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "29",
                        dataIndex: "qty_3",
                        key: "qty_3",
                        width: 50,
                        align: "right",
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_4",
                key: "qty_4",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "L",
                    dataIndex: "qty_4",
                    key: "qty_4",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "30",
                        dataIndex: "qty_4",
                        key: "qty_4",
                        width: 50,
                        align: "right",
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_5",
                key: "qty_5",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "XL",
                    dataIndex: "qty_5",
                    key: "qty_5",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "31",
                        dataIndex: "qty_5",
                        key: "qty_5",
                        width: 50,
                        align: "right",
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_6",
                key: "qty_6",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "XXL",
                    dataIndex: "qty_6",
                    key: "qty_6",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "32",
                        dataIndex: "qty_6",
                        key: "qty_6",
                        width: 50,
                        align: "right",
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_7",
                key: "qty_7",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_7",
                    key: "qty_7",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "33",
                        dataIndex: "qty_7",
                        key: "qty_7",
                        width: 50,
                        align: "right",
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_8",
                key: "qty_8",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_8",
                    key: "qty_8",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "34",
                        dataIndex: "qty_8",
                        key: "qty_8",
                        width: 50,
                        align: "right",
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_9",
                key: "qty_9",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_9",
                    key: "qty_9",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "35",
                        dataIndex: "qty_9",
                        key: "qty_9",
                        width: 50,
                        align: "right",
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_10",
                key: "qty_10",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_10",
                    key: "qty_10",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "36",
                        dataIndex: "qty_10",
                        key: "qty_10",
                        width: 50,
                        align: "right",
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_11",
                key: "qty_11",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_11",
                    key: "qty_11",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "38",
                        dataIndex: "qty_11",
                        key: "qty_11",
                        width: 50,
                        align: "right",
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_12",
                key: "qty_12",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_12",
                    key: "qty_12",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "40",
                        dataIndex: "qty_12",
                        key: "qty_12",
                        width: 50,
                        align: "right",
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_13",
                key: "qty_13",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_13",
                    key: "qty_13",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "42",
                        dataIndex: "qty_13",
                        key: "qty_13",
                        width: 50,
                        align: "right",
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_14",
                key: "qty_14",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_14",
                    key: "qty_14",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "44",
                        dataIndex: "qty_14",
                        key: "qty_14",
                        width: 50,
                        align: "right",
                      },
                    ],
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_15",
                key: "qty_15",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_15",
                    key: "qty_15",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "46",
                        dataIndex: "qty_15",
                        key: "qty_15",
                        width: 50,
                        align: "right",
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_16",
                key: "qty_16",
                width: 50,
                align: "right",
                className: "Border-Right-table border-z-index-1",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_16",
                    key: "qty_16",
                    width: 50,
                    align: "right",
                    className: "Border-Right-table border-z-index-1",
                    children: [
                      {
                        title: "48",
                        dataIndex: "qty_16",
                        key: "qty_16",
                        width: 50,
                        align: "right",
                        className: "Border-Right-table border-z-index-1",
                      }
                    ]
                  }]
              },
            ],
          }) :
  
          ///for All
          {
            children: [
              {
                title: "T-Jacket",
                dataIndex: "stage",
                key: "stage",
                width: 75,
                children: [
                  {
                    title: "Shirt/RTW-P",
                    dataIndex: "stage",
                    key: "stage",
                    width: 75,
                    children: [
                      {
                        title: "Shirt/RTW-R",
                        dataIndex: "stage",
                        key: "stage",
                        width: 75,
                        children: [
                          {
                            title: "Y-Adult",
                            dataIndex: "stage",
                            key: "stage",
                            width: 75,
                            children: [
                              {
                                title: "W-Plus",
                                dataIndex: "stage",
                                key: "stage",
                                width: 75,
                                children: [
                                  {
                                    title: "Kids",
                                    dataIndex: "stage",
                                    key: "stage",
                                    width: 75,
                                    children: [
                                      {
                                        title: "Men",
                                        dataIndex: "stage",
                                        key: "stage",
                                        width: 75,
                                        children: [
                                          {
                                            title: "Women",
                                            dataIndex: "stage",
                                            key: "stage",
                                            width: 75,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: '"2/3"',
                align: "right",
                dataIndex: "qty_1",
                key: "qty_1",
                width: 40,
                children: [
                  {
                    title: "1XL",
                    dataIndex: "qty_1",
                    key: "qty_1",
                    width: 40,
                    align: "right",
                    children: [
                      {
                        title: "XS",
                        dataIndex: "qty_1",
                        key: "qty_1",
                        width: 40,
                        align: "right",
                        children: [
                          {
                            title: "00",
                            dataIndex: "qty_1",
                            key: "qty_1",
                            width: 40,
                            align: "right",
                            children: [
                              {
                                title: "10W",
                                dataIndex: "qty_1",
                                key: "qty_1",
                                width: 40,
                                align: "right",
                                children: [
                                  {
                                    title: "2",
                                    dataIndex: "qty_1",
                                    key: "qty_1",
                                    width: 40,
                                    align: "right",
                                    children: [
                                      {
                                        title: "27",
                                        dataIndex: "qty_1",
                                        key: "qty_1",
                                        align: "right",
                                        width: 40,
                                        children: [
                                          {
                                            title: "23",
                                            dataIndex: "qty_1",
                                            key: "qty_1",
                                            width: 40,
                                            align: "right",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: '"4/5"',
                align: "right",
                dataIndex: "qty_2",
                key: "qty_2",
                width: 50,
                children: [
                  {
                    title: "2XL",
                    align: "right",
                    dataIndex: "qty_2",
                    key: "qty_2",
                    width: 55,
                    children: [
                      {
                        title: "S",
                        align: "right",
                        dataIndex: "qty_2",
                        key: "qty_2",
                        width: 55,
                        children: [
                          {
                            title: "0",
                            align: "right",
                            dataIndex: "qty_2",
                            key: "qty_2",
                            width: 55,
                            children: [
                              {
                                title: "12W",
                                align: "right",
                                dataIndex: "qty_2",
                                key: "qty_2",
                                width: 55,
                                children: [
                                  {
                                    title: "3",
                                    align: "right",
                                    dataIndex: "qty_2",
                                    key: "qty_2",
                                    width: 50,
                                    children: [
                                      {
                                        title: "28",
                                        align: "right",
                                        dataIndex: "qty_2",
                                        key: "qty_2",
                                        width: 50,
                                        children: [
                                          {
                                            title: "24",
                                            align: "right",
                                            dataIndex: "qty_2",
                                            key: "qty_2",
                                            width: 40,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: '"6/6X"',
                dataIndex: "qty_3",
                key: "qty_3",
                align: "right",
                width: 60,
                children: [
                  {
                    title: "3XL",
                    dataIndex: "qty_3",
                    key: "qty_3",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "M",
                        dataIndex: "qty_3",
                        key: "qty_3",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "1",
                            dataIndex: "qty_3",
                            key: "qty_3",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "14W",
                                dataIndex: "qty_3",
                                key: "qty_3",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "4",
                                    dataIndex: "qty_3",
                                    key: "qty_3",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "29",
                                        dataIndex: "qty_3",
                                        key: "qty_3",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "25",
                                            dataIndex: "qty_3",
                                            key: "qty_3",
                                            width: 45,
                                            align: "right",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_4",
                key: "qty_4",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_4",
                    key: "qty_4",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "L",
                        dataIndex: "qty_4",
                        key: "qty_4",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "2",
                            dataIndex: "qty_4",
                            key: "qty_4",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "16W",
                                dataIndex: "qty_4",
                                key: "qty_4",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "5",
                                    dataIndex: "qty_4",
                                    key: "qty_4",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "30",
                                        dataIndex: "qty_4",
                                        key: "qty_4",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "26",
                                            dataIndex: "qty_4",
                                            key: "qty_4",
                                            width: 40,
                                            align: "right",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_5",
                key: "qty_5",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_5",
                    key: "qty_5",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "XL",
                        dataIndex: "qty_5",
                        key: "qty_5",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "3",
                            dataIndex: "qty_5",
                            key: "qty_5",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "18W",
                                dataIndex: "qty_5",
                                key: "qty_5",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "6",
                                    dataIndex: "qty_5",
                                    key: "qty_5",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "31",
                                        dataIndex: "qty_5",
                                        key: "qty_5",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "27",
                                            dataIndex: "qty_5",
                                            key: "qty_5",
                                            width: 40,
                                            align: "right",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_6",
                key: "qty_6",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_6",
                    key: "qty_6",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "XXL",
                        dataIndex: "qty_6",
                        key: "qty_6",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "4",
                            dataIndex: "qty_6",
                            key: "qty_6",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "20W",
                                dataIndex: "qty_6",
                                key: "qty_6",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "6x",
                                    dataIndex: "qty_6",
                                    key: "qty_6",
                                    width: 50,
                                    align: "right",
  
                                    children: [
                                      {
                                        title: "32",
                                        dataIndex: "qty_6",
                                        key: "qty_6",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "28",
                                            dataIndex: "qty_6",
                                            key: "qty_6",
                                            width: 40,
                                            align: "right",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_7",
                key: "qty_7",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_7",
                    key: "qty_7",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_7",
                        key: "qty_7",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "5",
                            dataIndex: "qty_7",
                            key: "qty_7",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "22W",
                                dataIndex: "qty_7",
                                key: "qty_7",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "7",
                                    dataIndex: "qty_7",
                                    key: "qty_7",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "33",
                                        dataIndex: "qty_7",
                                        key: "qty_7",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "29",
                                            dataIndex: "qty_7",
                                            key: "qty_7",
                                            width: 40,
                                            align: "right",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_8",
                key: "qty_8",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_8",
                    key: "qty_8",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_8",
                        key: "qty_8",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "6",
                            dataIndex: "qty_8",
                            key: "qty_8",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "24W",
                                dataIndex: "qty_8",
                                key: "qty_8",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "8",
                                    dataIndex: "qty_8",
                                    key: "qty_8",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "34",
                                        dataIndex: "qty_8",
                                        key: "qty_8",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "30",
                                            dataIndex: "qty_8",
                                            key: "qty_8",
                                            width: 40,
                                            align: "right",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }
                    ]
                  }]
              },
              {
                title: "-",
                dataIndex: "qty_9",
                key: "qty_9",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_9",
                    key: "qty_9",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_9",
                        key: "qty_9",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "7",
                            dataIndex: "qty_9",
                            key: "qty_9",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "26W",
                                dataIndex: "qty_9",
                                key: "qty_9",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "10",
                                    dataIndex: "qty_9",
                                    key: "qty_9",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "35",
                                        dataIndex: "qty_9",
                                        key: "qty_9",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "31",
                                            dataIndex: "qty_9",
                                            key: "qty_9",
                                            width: 40,
                                            align: "right",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_10",
                key: "qty_10",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_10",
                    key: "qty_10",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_10",
                        key: "qty_10",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "8",
                            dataIndex: "qty_10",
                            key: "qty_10",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "28W",
                                dataIndex: "qty_10",
                                key: "qty_10",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "12",
                                    dataIndex: "qty_10",
                                    key: "qty_10",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "36",
                                        dataIndex: "qty_10",
                                        key: "qty_10",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "32",
                                            dataIndex: "qty_10",
                                            key: "qty_10",
                                            width: 40,
                                            align: "right",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_11",
                key: "qty_11",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_11",
                    key: "qty_11",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_11",
                        key: "qty_11",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "9",
                            dataIndex: "qty_11",
                            key: "qty_11",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "30W",
                                dataIndex: "qty_11",
                                key: "qty_11",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "14",
                                    dataIndex: "qty_11",
                                    key: "qty_11",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "38",
                                        dataIndex: "qty_11",
                                        key: "qty_11",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "33",
                                            dataIndex: "qty_11",
                                            key: "qty_11",
                                            width: 40,
                                            align: "right",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_12",
                key: "qty_12",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_12",
                    key: "qty_12",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_12",
                        key: "qty_12",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "10",
                            dataIndex: "qty_12",
                            key: "qty_12",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "32W",
                                dataIndex: "qty_12",
                                key: "qty_12",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "16",
                                    dataIndex: "qty_12",
                                    key: "qty_12",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "40",
                                        dataIndex: "qty_12",
                                        key: "qty_12",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "34",
                                            dataIndex: "qty_12",
                                            key: "qty_12",
                                            width: 40,
                                            align: "right",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_13",
                key: "qty_13",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_13",
                    key: "qty_13",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_13",
                        key: "qty_13",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "-",
                            dataIndex: "qty_13",
                            key: "qty_13",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "-",
                                dataIndex: "qty_13",
                                key: "qty_13",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "18",
                                    dataIndex: "qty_13",
                                    key: "qty_13",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "42",
                                        dataIndex: "qty_13",
                                        key: "qty_13",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "35",
                                            dataIndex: "qty_13",
                                            key: "qty_13",
                                            width: 40,
                                            align: "right",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_14",
                key: "qty_14",
                width: 50,
                align: "right",
  
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_14",
                    key: "qty_14",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_14",
                        key: "qty_14",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "-",
                            dataIndex: "qty_14",
                            key: "qty_14",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "-",
                                dataIndex: "qty_14",
                                key: "qty_14",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "20",
                                    dataIndex: "qty_14",
                                    key: "qty_14",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "44",
                                        dataIndex: "qty_14",
                                        key: "qty_14",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "36",
                                            dataIndex: "qty_14",
                                            key: "qty_14",
                                            width: 40,
                                            align: "right",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          }
                        ]
                      }]
                  },
                ],
              },
              {
                title: "-",
                dataIndex: "qty_15",
                key: "qty_15",
                width: 50,
                align: "right",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_15",
                    key: "qty_15",
                    width: 50,
                    align: "right",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_15",
                        key: "qty_15",
                        width: 50,
                        align: "right",
                        children: [
                          {
                            title: "-",
                            dataIndex: "qty_15",
                            key: "qty_15",
                            width: 50,
                            align: "right",
                            children: [
                              {
                                title: "-",
                                dataIndex: "qty_15",
                                key: "qty_15",
                                width: 50,
                                align: "right",
                                children: [
                                  {
                                    title: "-",
                                    dataIndex: "qty_15",
                                    key: "qty_15",
                                    width: 50,
                                    align: "right",
                                    children: [
                                      {
                                        title: "46",
                                        dataIndex: "qty_15",
                                        key: "qty_15",
                                        width: 50,
                                        align: "right",
                                        children: [
                                          {
                                            title: "37",
                                            dataIndex: "qty_15",
                                            key: "qty_15",
                                            width: 40,
                                            align: "right",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
              {
                title: "-",
                dataIndex: "qty_16",
                key: "qty_16",
                width: 50,
                align: "right",
                className: "Border-Right-table border-z-index-1",
                children: [
                  {
                    title: "-",
                    dataIndex: "qty_16",
                    key: "qty_16",
                    width: 50,
                    align: "right",
                    className: "Border-Right-table border-z-index-1",
                    children: [
                      {
                        title: "-",
                        dataIndex: "qty_16",
                        key: "qty_16",
                        width: 50,
                        align: "right",
                        className: "Border-Right-table border-z-index-1",
                        children: [
                          {
                            title: "-",
                            dataIndex: "qty_16",
                            key: "qty_16",
                            width: 50,
                            align: "right",
                            className: "Border-Right-table border-z-index-1",
                            children: [
                              {
                                title: "-",
                                dataIndex: "qty_16",
                                key: "qty_16",
                                width: 50,
                                align: "right",
                                className: "Border-Right-table border-z-index-1",
                                children: [
                                  {
                                    title: "-",
                                    dataIndex: "qty_16",
                                    key: "qty_16",
                                    width: 50,
                                    align: "right",
                                    className: "Border-Right-table border-z-index-1",
                                    children: [
                                      {
                                        title: "48",
                                        dataIndex: "qty_16",
                                        key: "qty_16",
                                        width: 50,
                                        align: "right",
                                        className: "Border-Right-table border-z-index-1",
                                        children: [
                                          {
                                            title: "38",
                                            dataIndex: "qty_16",
                                            key: "qty_16",
                                            width: 40,
                                            align: "right",
                                            className: "Border-Right-table border-z-index-1",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }]
                  }
                ]
              },
            ],
          },
    {
      title: "Qty",
      dataIndex: "sum_quantity",
      key: "sum_quantity",
      align: 'center',
      className: "border-z-index-1"
      // width: 80
    },
    {
      title: "Bulk Order QTY",
      dataIndex: "bulk_quantity",
      key: "bulk_quantity",
      align: 'center',
      className: "border-z-index-1"
      // width: 80
    },
    {
      title: "ETA",
      dataIndex: "burgen_indc_date",
      key: "burgen_indc_date",
      // width: 60,
      render: (text, record) => {
        return <>{moment(record.burgen_indc_date).format("MM-DD-YY")}</>
      },
    },
    
    {
      title: "Ex Factory",
      dataIndex: "ex_factory_date",
      key: "ex_factory_date",
      // width: 80,
      render: (text, record) => {
        return <>{moment(record.ex_factory_date).format("MM-DD-YY")}</>
      },
    },
    {
      title: "Revised",
      dataIndex: "extensiondate",
      key: "extensiondate",
      // width: 80,
      render: (text, record) => {
        return <>{moment(record.extensiondate).format("MM-DD-YY")}</>
      },
    },
    {
      title: "Ship Mode",
      dataIndex: "ship_via_description",
      key: "ship_via_description",
      // width: 80,
    },
    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_name",
      // width: 80,
    },
    {
      title: "Fabric",
      dataIndex: "fabric_name",
      key: "fabric_name",
      // width: 130,
    },
    {
      title: "Fabric Avl",
      dataIndex: "fabric_avl",
      key: "fabric_avl",
      // width: 80,
      render: (text, record) => {
        return <>{record.fabric_avl === "null" ? '' : record.fabric_avl }
        </>
      },
    },
    {
      title: "Fabric ETA",
      dataIndex: "fabriceta_date",
      key: "fabriceta_date",
      // width: 80,
      render: (text, record) => {
        return <>{(record.fabriceta_date === "1900-01-01T00:00:00.000Z" || record.fabriceta_date === null) ? '' : moment(record.fabriceta_date).format("MM-DD-YY")}
        </>
      },
    },
    {
      title: "Mfg",
      dataIndex: "mfg",
      key: "mfg",
      // width: 80,
      render: (text, record) => {
        return <>{record.mfg === "null" ? '' : record.mfg }
        </>
      },
    },
    {
      title: "PP Status",
      dataIndex: "ppstatus",
      key: "ppstatus",
      // width: 80,
      render: (text, record) => {
        return <>{record.ppstatus === "null" ? '' : record.ppstatus }
        </>
      },
    },
  ];

  const handleGender = (gender_id) => {
    dispatch(getGenderCategoryChange(token, gender_id));
    dispatch(getStyleChange(token, gender_id, null, null));
    form.setFieldsValue({
      gender_category_id: "All",
      style_id: "All",
      sku: "All"
    })
  }


  const handleStyle = (gender_category_id) => {
    dispatch(getStyleChange(token, form.getFieldValue().gender_id, gender_category_id, null))
    form.setFieldsValue({
      style_id: "All",
      sku: "All"
    })
  }

  const handleSku = (sku_id) => {
    if (sku_id === null) {
      dispatch(getSku(token, null, null, null));
    } else {
      dispatch(getSku(token, form.getFieldValue().gender_id, form.getFieldValue().gender_category_id, sku_id));
    }
    form.setFieldsValue({
      sku: "All"
    })
  }

  function debounce(cb, delay = 1000) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        cb(...args)
      }, delay)
    }
  }



  const handleSkuSearch = () => {
    dispatch(getSku(token, null, null, null));
  }

  const optimizedFn = useCallback(debounce(handleSkuSearch), []);


  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "SKU": v.item_code,
          "Inseam": v.inseam,
          "Style": v.style_name,
          "Wash": v.wash_name,
          "Season": v.new_season,
          "Status": v.product_status,
          "Bulk Order #": v.bulk_order_no,
          "Bulk Order Date": moment(v.po_date).format("MM-DD-YYYY"),
          "Stage": v.stage,
          "=\"\"2/3\"\"&CHAR(10)&\"\"00\"\"&CHAR(10)&\"\"XS\"\"&CHAR(10)&\"\"2\"\"&CHAR(10)&\"\"27\"\"&CHAR(10)&\"\"23\"\"": v.qty_1,
          "=\"\"4/5\"\"&CHAR(10)&\"\"0\"\"&CHAR(10)&\"\"S\"\"&CHAR(10)&\"\"3\"\"&CHAR(10)&\"\"28\"\"&CHAR(10)&\"\"24\"\"": v.qty_2,
          "=\"\"6/6X\"\"&CHAR(10)&\"\"1\"\"&CHAR(10)&\"\"M\"\"&CHAR(10)&\"\"4\"\"&CHAR(10)&\"\"29\"\"&CHAR(10)&\"\"25\"\"": v.qty_3,
          "=\"\"-\"\"&CHAR(10)&\"\"2\"\"&CHAR(10)&\"\"L\"\"&CHAR(10)&\"\"5\"\"&CHAR(10)&\"\"30\"\"&CHAR(10)&\"\"26\"\"": v.qty_4,
          "=\"\"-\"\"&CHAR(10)&\"\"3\"\"&CHAR(10)&\"\"XL\"\"&CHAR(10)&\"\"6\"\"&CHAR(10)&\"\"31\"\"&CHAR(10)&\"\"27\"\"": v.qty_5,
          "=\"\"-\"\"&CHAR(10)&\"\"4\"\"&CHAR(10)&\"\"1XL\"\"&CHAR(10)&\"\"6x\"\"&CHAR(10)&\"\"32\"\"&CHAR(10)&\"\"28\"\"": v.qty_6,
          "=\"\"-\"\"&CHAR(10)&\"\"5\"\"&CHAR(10)&\"\"2XL\"\"&CHAR(10)&\"\"7\"\"&CHAR(10)&\"\"33\"\"&CHAR(10)&\"\"29\"\"": v.qty_7,
          "=\"\"-\"\"&CHAR(10)&\"\"6\"\"&CHAR(10)&\"\"3XL\"\"&CHAR(10)&\"\"7x\"\"&CHAR(10)&\"\"34\"\"&CHAR(10)&\"\"30\"\"": v.qty_8,
          "=\"\"-\"\"&CHAR(10)&\"\"7\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"8\"\"&CHAR(10)&\"\"35\"\"&CHAR(10)&\"\"31\"\"": v.qty_9,
          "=\"\"-\"\"&CHAR(10)&\"\"8\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"10\"\"&CHAR(10)&\"\"36\"\"&CHAR(10)&\"\"32\"\"": v.qty_10,
          "=\"\"-\"\"&CHAR(10)&\"\"9\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"12\"\"&CHAR(10)&\"\"38\"\"&CHAR(10)&\"\"33\"\"": v.qty_11,
          "=\"\"-\"\"&CHAR(10)&\"\"10\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"14\"\"&CHAR(10)&\"\"40\"\"&CHAR(10)&\"\"34\"\"": v.qty_12,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"16\"\"&CHAR(10)&\"\"42\"\"&CHAR(10)&\"\"35\"\"": v.qty_13,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"18\"\"&CHAR(10)&\"\"44\"\"&CHAR(10)&\"\"36\"\"": v.qty_14,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"20\"\"&CHAR(10)&\"\"46\"\"&CHAR(10)&\"\"37\"\"": v.qty_15,
          "=\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"-\"\"&CHAR(10)&\"\"48\"\"&CHAR(10)&\"\"38\"\"": v.qty_16,
          "Qty": v.sum_quantity,
          "Bulk Order QTY": v.bulk_quantity,
          "ETA": moment(v.delivery_date).format("MM-DD-YYYY"),
          "Ex Factory": moment(v.ex_factory_date).format("MM-DD-YYYY"),
          "Revised ": moment(v.extensiondate).format("MM-DD-YYYY"),
          "Ship Mode": v.ship_via_description,
          "Gender": v.gender_name,
          "Fabric": v.fabric_name,
          "Fabric Avl": v.fabric_avl === "null" ? '' : v.fabric_avl,
          "Fabric ETA ": (v.fabriceta_date === "1900-01-01T00:00:00.000Z" || v.fabriceta_date === null) ? '' : moment(v.fabriceta_date).format("MM-DD-YY"),
          "Mfg": v.mfg === "null" ? '' : v.mfg,
          "PP Status": v.ppstatus === "null" ? '' : v.ppstatus,
        };
      })
    )
  }

  const totalQuantity = () => {
    let sumQty = productionList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.sum_quantity);
    }, 0);
    return sumQty;
  }


  return (
    <div className="header_class business_header_oms intransit">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class">
                <CSVLink
                  filename={"Production-Report.csv"}
                  data={excelData}
                  onClick={() => {
                  }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                  />
                </CSVLink>

              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Production Report
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              <hr className="my-1" />
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Gender</label>
                    <div className="w-100">
                      {/* <Form.Item name="gender_id" initialValue={"All"}> */}
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          defaultValue={"All"}
                          onChange={(e) => {
                            console.log("eeeeeeeeeee", e);
                            setIsGenerate(e);
                          }}
                        >
                          <Option value={null}>All</Option>
                          {gender &&
                            gender?.payload?.data.map((it, index) => {
                              return (
                                <Option key={index} value={it.gender_id}>
                                  {it.gender_name}
                                </Option>
                              );
                            })}
                        </Select>
                      {/* </Form.Item> */}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Wash</label>
                    <div className="w-100">
                      <Form.Item name="wash_id" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().wash_id}
                          className={"select_Inputs"}
                          showSearch

                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionB?.value !== null ?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                          }
                        >
                          <Option value={null}>All</Option>
                          {wash &&
                            wash?.payload?.data?.map(
                              (status, index) => {
                                return (
                                  <Option
                                    key={status.wash_id}
                                    value={status.wash_id}
                                  >
                                    {status.wash_name}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Style</label>
                    <div className="w-100">
                      <Form.Item initialValue={"All"} name="style_id">
                        <Select
                          className={"select_Inputs"}
                          showSearch

                          optionFilterProp="children"

                          size="small"
                          onChange={(e) => handleSku(e)}
                          filterSort={(optionA, optionB) =>
                            optionB?.value !== null ?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                          }
                        >
                          <Option value={null}>All</Option>
                          {
                            styleChange && styleChange?.payload?.data.length >= 0 ? (
                              styleChange && styleChange?.payload?.data.map((style, index) => {
                                return (
                                  <Option key={index} value={style.style_id}>
                                    {style.style_name}
                                  </Option>
                                );
                              })
                            ) : (
                              style && style?.payload?.data.map((style, index) => {
                                return (
                                  <Option key={index} value={style.style_id}>
                                    {style.style_name}
                                  </Option>
                                );
                              })
                            )
                          }
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Sku</label>
                    <div className="w-100">
                      {/* <Form.Item name="sku" initialValue={"All"}> */}
                        <Select
                          notFoundContent={Sku.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Loader type="Oval" color="#3c4b58" height={20} width={20} />
                          </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
                          className={"select_Inputs"}
                          showSearch
                          onSearch={(value) => {
                            optimizedFn(value)
                          }}
                          defaultValue={"All"}
                          onChange={(e) => {
                            console.log("eeeeeeeeeee", e);
                            setIsSKU(e);
                          }}
                          optionFilterProp="children"
                          size="small"
                        >
                          <Option value="All">All</Option>
                          {Sku &&
                            Sku?.payload?.data.map((it, index) => {
                              return (

                                <Option key={index} value={it.sku}>
                                  {it.sku}
                                </Option>
                              );
                            })}
                        </Select>
                      {/* </Form.Item> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="main_form row form-margin">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Season</label>
                    <div className="w-100">
                      <Form.Item name="current_season_id" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().current_season_id}
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionB?.value !== null ?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                          }
                        >
                          <Option value={null}>All</Option>
                          {season &&
                            season?.payload?.data?.map(
                              (status, index) => {
                                return (
                                  <Option
                                    key={status.season_id}
                                    value={status.season_id}
                                  >
                                    {status.season_name}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    {/* <label className=" no_visibility">none</label> */}
                    <div className="w-100" style={{ marginTop: "4px" }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="submit-btn-btm m-0 "
                      >
                        Generate
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div
        className="table_content international_order"

        // style={{ maxWidth: "1400px" }}
      >
        <div className="overflow"  >
          {

          }
          {production?.payload?.data !== undefined ?
            (
              production?.payload?.data.length > 0 ? (
                <Table
                  className="fix-scroll-table  table-header-sticky  border-line-th  bottom-align-th  table-td-border-right  th-general-style  
              td-general-style even-tr-style border"
                  ref={tableRef}
                  size="small"
                  bordered={false}
                  type="company"
                  headerClassName='abc'
                  dataSource={productionList}
                  columns={colums}
                  loading={production.loading}
                  pagination={{ pageSize: 500 }}
                  scroll={{ x: 'max-content' }}
                  summary={(pageData) => {
                    let Size1 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_1 === "" ? 0 : currentValue.qty_1
                      ));
                    },
                      0);
                    let Size2 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_2 === "" ? 0 : currentValue.qty_2
                      ));
                    },
                      0);
                    let Size3 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_3 === "" ? 0 : currentValue.qty_3
                      ));
                    },
                      0);
                    let Size4 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_4 === "" ? 0 : currentValue.qty_4

                      ));
                    },
                      0);
                    let Size5 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_5 === "" ? 0 : currentValue.qty_5
                      ));
                    },
                      0);
                    let Size6 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_6 === "" ? 0 : currentValue.qty_6
                      ));
                    },
                      0);
                    let Size7 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_7 === "" ? 0 : currentValue.qty_7
                      ));
                    },
                      0);
                    let Size8 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_8 === "" ? 0 : currentValue.qty_8
                      ));
                    },
                      0);
                    let Size9 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_9 === "" ? 0 : currentValue.qty_9
                      ));
                    },
                      0);
                    let Size10 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_10 === "" ? 0 : currentValue.qty_10
                      ));
                    },
                      0);
                    let Size11 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_11 === "" ? 0 : currentValue.qty_11
                      ));
                    },
                      0);
                    let Size12 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_12 === "" ? 0 : currentValue.qty_12
                      ));
                    },
                      0);
                    let Size13 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_13 === "" ? 0 : currentValue.qty_13
                      ));
                    },
                      0);
                    let Size14 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_14 === "" ? 0 : currentValue.qty_14
                      ));
                    },
                      0);
                    let Size15 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_15 === "" ? 0 : currentValue.qty_15
                      ));
                    },
                      0);
                    let Size16 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_16 === "" ? 0 : currentValue.qty_16
                      ));
                    },
                      0);
                    let Quantity = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.sum_quantity
                      ));
                    },
                      0);
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size1.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size2.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size3.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size4.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size5.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size6.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size7.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size8.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size9.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size10.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size11.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size12.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size13.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size14.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size15.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size16.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "center" }} className="totalquantity">
                              {Quantity.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              ) : (
                <div className="no-Data">
                  {/* {} */}
                  <h1>No Data</h1>
                </div>
              )
            ) : (
              loading && (
                <div className="loader" style={{ marginTop: "20px" }}>
                  <Spin size="medium" />
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  );
};

export default ProductionReport;
