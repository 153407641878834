import React, { useState, useCallback } from 'react'
import PageHeader from '../../components/PageHeader/PageHeader'
import { Button, Form, Input, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { updateProductImage } from '../../redux/actions/productImageUrl.action';
import { ReadCookie } from '../../utils/readCookie';
import Swal from 'sweetalert2';
import Loader from "react-loader-spinner";
import { getSku } from '../../redux/actions/sku.action';
import { Row as Brow, Col as Bcol } from "reactstrap";



const { Option } = Select;
const ProductImageUrl = () => {
    const [form] = Form.useForm();
    const { Sku } = useSelector((state) => state);
    const dispatch = useDispatch();
    const token = ReadCookie("token");
    const [loading, setLoading] = useState(false);
    

    const onFinish = async () => {
        setLoading(true)
        await form.validateFields().then((values) => {
            dispatch(updateProductImage(token, values)).then((res) => {
                if (res.type === "UPDATE_PRODUCT_IMAGE_URL_SUCCESS") {
                    Swal.fire({
                        icon: "success",
                        title: "Successfull",
                        text: "Image Url Successfully Updated!"
                    });
                    form.setFieldsValue({
                        sku: null,
                        url: null
                    })
                    setLoading(false)
                } else if (res.type === "UPDATE_PRODUCT_IMAGE_URL_ERROR") {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: res.payload.data
                    });
                    setLoading(false)
                }
            })
        });

    }


    function debounce(cb, delay = 1000) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }



    const handleSkuSearch = (val) => {
        if (val === "" || val === "All") return;
        dispatch(getSku(token, null, null, null))
    };


    const optimizedFn = useCallback(debounce(handleSkuSearch), []);

    return (
        <div className="styleContainer">
            <div className="styleBody h-100">
                <PageHeader
                    title={"Product Image Url"}
                    minimize={false}
                    check={false}
                />
                {loading ?
                    <div className='loaderDiv' >
                        <Loader
                            type="Grid"
                            color="#212121"
                            height={50}
                            width={50}
                        />
                    </div> :
                    null}

                <div className="bodyStyle">
                    <div className="row">
                        <div className="col" style={{ margin: 10 }}>
                            <Form
                                form={form}
                                name="control-hooks"
                                onFinish={onFinish}
                            >

                                <Brow>
                                    <Bcol md={12} xl={9} xxl={10} className="pbpx-10">
                                        <Brow>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">

                                                    <Form.Item
                                                        name="sku"
                                                        label="SKU"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please Select SKU",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            notFoundContent={Sku.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <Loader type="Oval" color="#3c4b58" height={20} width={20} />
                                                            </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
                                                            className={"select_Inputs"}
                                                            showSearch
                                                            onSearch={(value) => {
                                                                optimizedFn(value)
                                                            }}
                                                            // filterSort={(optionA, optionB) =>
                                                            //     optionB?.value !== "All" ?
                                                            //         optionA?.children?.toLowerCase().localeCompare(optionB?.children?.toLowerCase()) : ''
                                                            // }
                                                            optionFilterProp="children"
                                                        // size="small"
                                                        >
                                                            {Sku &&
                                                                Sku?.payload?.data.map((it, index) => {
                                                                    return (

                                                                        <Option key={index} value={it.sku}>
                                                                            {it.sku}
                                                                        </Option>
                                                                    );
                                                                })}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <div className="firstSectionRo">
                                                    <Form.Item
                                                        name="url"
                                                        label="Image Url"
                                                        labelAlign="left"
                                                        labelCol={{ span: 10 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please Enter Your Image Url",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            
                                                            placeholder='Enter Your Image URL'

                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Bcol>
                                            <Bcol lg={6} xl={4} xxl={3}>
                                                <Button
                                                    htmlType="submit"
                                                    type="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </Bcol>

                                        </Brow>
                                    </Bcol>

                                </Brow>
                            </Form>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}

export default ProductImageUrl