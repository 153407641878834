import { useState, useRef, useEffect } from "react";
import "./Distributor.css";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { Spin, DatePicker, Form } from "antd";
import moment from "moment";
import { useDownloadExcel } from "react-export-table-to-excel";
import numeral from "numeral";
import { TabTitle } from "../../utils/GeneralFunction";
import { getCustomerAgingReport } from "../../redux/actions/customerPayment.action";
import { CSVLink } from "react-csv";
import csv from "../../assets/images/csv.png";
import { getGender } from "../../redux/actions/gender.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getFactor } from "../../redux/actions/factor.action";
const { Option } = Select;
const dateFormatList = ["MM-DD-YYYY"];

const CustomerPaymentLedgerNew = ({ token }) => {
  TabTitle("AR Aging Detail")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    paymentterm,
    factor,
    gender
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const [loading, setLoading] = useState(false);
  const [StockwiseReportData, setStockWiseReportData] = useState([]);
  const [excelData, setExcelData] = useState([])
  const initialDate = moment('2026-12-31')

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "AR-Aging-Detail",
    sheet: "AR-Aging-Detail",
  });

  let result =
    StockwiseReportData &&
    StockwiseReportData.reduce(function (r, a) {
      r[a.customer_id] =
        r[a.customer_id] || [];
      r[a.customer_id].push(a);
      return r;
    }, Object.create(null));

  const wiseStockReport = result && Object.values(result);



  useEffect(() => {
    dispatch(getGender(token, menuId));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getFactor(token, menuId));
  }, [dispatch, menuId, token]);


  const onFinish = async (e) => {
    await form.validateFields().then((values) => {
      setLoading(true);
      dispatch(getCustomerAgingReport(token, values)).then((res) => {
        setLoading(false);
        setStockWiseReportData(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    });
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Customer": v.customer_name,
          "Invoice#": v.invoice_header_no,
          "Invoice Date": moment(v.invoice_date).format("MM-DD-YYYY"),
          "PO Number": v.po_number,
          "Term": v.term_name,
          "Invoice Amount": numeral(v.invoice_amount).format("$0,00.00"),
          "Credit Note": numeral(v.note_amount_us).format("$0,00.00"),
          "Payment": numeral(v.payment_us).format("$0,00.00"),
          "Balance": numeral(v.balance).format("$0,00.00"),
          "Status": v.status,
          "Days": v.daysdiff,
          "Gender": v.gender_name,
          "Other Credit": numeral(v.credit_balance).format("$0,00.00"),
          "Unapplied Amount": numeral(v.unapplied_amount).format("$0,00.00"),
        };
      })
    )
  }

  const handlePrint = () => {
    window.print();
  };
  const invoice_amount = StockwiseReportData.reduce((acc, item, index) => acc + parseFloat(item.invoice_amount), 0)
  const credit_invoice_amount = StockwiseReportData.reduce((acc, item, index) => acc + parseFloat(item.note_amount_us), 0)
  const payment_invoice_amount = StockwiseReportData.reduce((acc, item, index) => acc + parseFloat(item.payment_us), 0)
  const balance_invoice_amount = StockwiseReportData.reduce((acc, item, index) => acc + parseFloat(item.balance), 0)
  const available_credit_amount = StockwiseReportData.reduce((acc, item, index) => acc + parseFloat(item.credit_balance), 0)
  const unapplied_amount = StockwiseReportData.reduce((acc, item, index) => acc + parseFloat(item.unapplied_amount), 0)

  let amount = balance_invoice_amount - available_credit_amount - unapplied_amount

  return (
    <div className="distributor header_class stock_style_wise" style={{ paddingBottom: "5px" }}>
      <div className="row justify-content-between distributr">
        <div className="col-3 d-flex align-items-sm-center box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-3 box-space right-content-header text-sm-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8 printing_class">
              <div className="print_icon printing_class">
                <i
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  onClick={handlePrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div
                className="print_icon printing_class"
                name="ImgExcel"
                alt="Export to excel"
                id="ImgExcel"
                title="Export to excel"
                src={xlsx}
              >
                {wiseStockReport ? (
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    onClick={onDownload}
                    style={{
                      borderWidth: "0px",
                      height: "14px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                )}
              </div>
              <div className="print_icon printing_class" >
                <CSVLink
                  filename={"AR-Aging-Detail.csv"}
                  data={excelData}
                  onClick={() => {
                  }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={csv}
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              AR Aging Detail
              <br />
              Rpt # 489
              <br />
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="business_class_oms distributr">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div>
              <div className="main_form row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" >To:</label>
                    <div className="w-100">
                      <Form.Item
                        className="full_date"
                        name="to_date"
                        initialValue={moment().endOf('year')}
                      >
                        <DatePicker
                          size="small"
                          defaultValue={moment().format("MM-DD-YYYY")}
                          className={"input-width"}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" >Gender:</label>
                    <div className="w-100">
                      <Form.Item
                        name="gender"
                        labelAlign="left"
                        
                        
                        initialValue={"All"}
                      >
                        <Select
                          size='small'
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          className="styleInput w-100"
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                        >
                          <Option value={null}>All</Option>
                          {
                            gender?.payload?.data.map((val) => {
                              return (
                                <Option key={val.gender_id} value={val.gender_id}>{val.gender_name}</Option>
                              )
                            })
                          }
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" >Terms:</label>
                    <div className="w-100">
                      <Form.Item
                        name="terms"
                        labelAlign="left"
                        
                        
                        initialValue={"All"}
                      >
                        <Select
                          size='small'
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          className="styleInput w-100"
                          defaultValue={form.getFieldValue().terms}
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                        >
                          <Option value={null}>All</Option>
                          {
                            paymentterm?.payload?.data.map((val) => {
                              return (
                                <Option value={val.term_id}>{val.term_name}</Option>
                              )
                            })
                          }
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" >Pay Class:</label>
                    <div className="w-100">
                      <Form.Item
                        name="paymentClass"
                        labelAlign="left"
                        initialValue={"All"}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          className="styleInput w-100"
                          filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                          showSearch
                          allowClear
                          loading={factor.loading}
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                        >
                          {factor?.payload?.data.map(
                            (item) => {
                              return (
                                <Select.Option
                                  key={item.factor_id}
                                  
                                  value={item.factor_id}
                                >
                                  {item.factor_name}
                                </Select.Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12">
                  <div className=" d-flex flex-row align-items-baseline" style={{ justifyContent: "end" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-btm m-0"
                    >
                      Generate
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div className="business_class_oms " ref={tableRef}>
        {
          (
            loading ? (
              <div className="loader" style={{ marginTop: "20px" }}>
                <Spin size="medium" />
              </div>
            ) : (
              wiseStockReport.length > 0 ? (
                <>
                  {
                    wiseStockReport.map((data, index) => {
                      let invoiceAmount = null;
                      let creditNote = null;
                      let payment = null;
                      let balance = null;
                      let creditBalance = null;
                      let unappliedAmount = null;

                      for (let i = 0; i < data.length; i++) {
                        invoiceAmount += parseFloat(data[i].invoice_amount);
                        creditNote += parseFloat(data[i].note_amount_us);
                        payment += parseFloat(data[i].payment_us);
                        balance += parseFloat(data[i].balance);
                        creditBalance += parseFloat(data[i].credit_balance);
                        unappliedAmount += parseFloat(data[i].unapplied_amount);
                      }
                      return (
                        <div key={index}>
                          <div className="table_data-Heading distributr font_weight" >
                            {data[0].customer_name}
                          </div>
                          <table className="table_distributor-data distributr">
                            <tbody>
                              <tr className=" font_weight">
                                <td className="font-weig">#</td>
                                <td
                                  style={{ width: "90px", fontWeight: "800" }}
                                  className="font-weig"
                                >
                                  Invoice#
                                </td>
                                <td className="font-weig" style={{ width: "90px" }}>Invoice Date</td>
                                <td className="font-weig" style={{ width: "180px" }}>PO Number</td>
                                <td className="font-weig" style={{ width: "120px" }}>Term</td>
                                <td className="font-weig" style={{ textAlign: "right", width: "100px", paddingRight: "5px" }} >Invoice Amount</td>
                                <td className="font-weig" style={{ textAlign: "right", width: "100px", paddingRight: "5px" }} >Credit Note</td>
                                <td className="font-weig" style={{ textAlign: "right", width: "100px", paddingRight: "5px" }} >Payment</td>
                                <td className="font-weig" style={{ textAlign: "right", width: "100px", paddingRight: "5px" }} >Balance</td>
                                <td className="font-weig" style={{ width: "100px" }} >Status</td>
                                <td className="font-weig" style={{ width: "100px" }} >Days</td>
                                <td className="font-weig" style={{ width: "100px" }} >Gender</td>
                              </tr>
                              {data.map((values, index) => {
                                if (values.invoice_header_no !== 0) {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{values.invoice_header_no}</td>
                                      <td>{moment(values.invoice_date).format("MM-DD-YYYY")}</td>
                                      <td>{values.po_number}</td>
                                      <td>{values.term_name}</td>
                                      <td style={{ textAlign: "right", paddingRight: "5px" }}>
                                        ${numeral(values.invoice_amount).format("0,00.00")}
                                      </td>
                                      <td style={{ textAlign: "right", paddingRight: "5px" }}>
                                        ${numeral(values.note_amount_us).format("0,00.00")}
                                      </td>
                                      <td style={{ textAlign: "right", paddingRight: "5px" }}>
                                        ${numeral(values.payment_us).format("0,00.00")}
                                      </td>
                                      <td style={{ textAlign: "right", paddingRight: "5px" }}>
                                        ${numeral(values.balance).format("0,00.00")}
                                      </td>
                                      <td>{values.status}</td>
                                      <td>{values.daysdiff}</td>
                                      <td>{values.gender_name}</td>
                                    </tr>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                              <tr>
                                <td className="font-weig"></td>
                                <td className="font-weig" colSpan={2}> Other Credit : ${numeral(creditBalance).format("0,00.00")} </td>
                                <td className="font-weig"> Unapplied Amount : ${numeral(unappliedAmount).format("0,00.00")} </td>
                                <td className="font-weig">Total</td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  ${numeral(invoiceAmount).format("0,00.00")}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  ${numeral(creditNote).format("0,00.00")}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  ${numeral(payment).format("0,00.00")}
                                </td>
                                <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px" }}>
                                  ${numeral(balance).format("0,00.00")}
                                </td>
                                <td className="font-weig"></td>
                                <td className="font-weig"></td>
                                <td className="font-weig"></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      );
                    })
                  }
                  <table className="table_distributor-data distributr" style={{ marginTop: "10px" }}>
                    <tbody>
                      <tr>
                        {/* <td className="font-weig"></td> */}
                        <td className="font-weig" colSpan={3} style={{ width: "160px" }}> 
                        {/* Available Credit :  ${numeral(available_credit_amount).format("0,00.00")}  */}
                        </td>
                        <td className="font-weig" style={{ width: "180px" }}> 
                        {/* Unapplied Amount :  ${numeral(unapplied_amount).format("0,00.00")}  */}
                        </td>
                        <td className="font-weig" style={{ width: "120px" }}>Total</td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px", width: "100px" }}>
                          {/* ${numeral(invoice_amount).format("0,00.00")} */}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px", width: "100px" }}>
                          {/* ${numeral(credit_invoice_amount).format("0,00.00")} */}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px", width: "100px" }}>
                          {/* ${numeral(payment_invoice_amount).format("0,00.00")} */}
                        </td>
                        <td className="font-weig" style={{ textAlign: "right", paddingRight: "2px", width: "100px" }}>
                          ${numeral(amount).format("0,00.00")}
                        </td>
                        <td className="font-weig" style={{ width: "100px" }}></td>
                        <td className="font-weig" style={{ width: "100px" }}></td>
                        <td className="font-weig" style={{ width: "100px" }}></td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ) : (
                <div className="no-Data">
                  <h1>No Data</h1>
                </div>
              )
            )
          )
        }
      </div>
    </div>
  );
};

export default CustomerPaymentLedgerNew;