import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { Row as Brow, Col as Bcol } from "reactstrap";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import { getAdminPaymentTypes } from "../../redux/actions/adminPaymentType.action"
import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Table, Spin
} from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import { getPendingSummary } from "../../redux/actions/pendingSummary.action";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import numeral from "numeral";
import { loadCustomers } from "../../redux/actions/customerForm";
import Loader from "react-spinners/BarLoader";
import { getARAccounts } from "../../redux/actions/AR_Account.action";
import { TabTitle } from "../../utils/GeneralFunction";
import { getPaymentReceipt } from "../../redux/actions/paymentReceipt.action";
import { CSVLink } from "react-csv";
const { Option } = Select;

const PaymentReceiptReport = () => {
  TabTitle("Payment Receipt")
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const [toogle, setToogle] = useState(false);
  const submitButton = useRef(null);
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const [pendingSummaryList, setPendingSummaryList] = useState([]);
  const [customer, setCustomer] = useState([])
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const customerID = queryParams.get("customer_id");
  const companyId = ReadCookie("defaultCompany");
  const company_id = ReadCookie("defaultCompany");
  const {
    getPendingSummaryReducer,
    customerForms,
    adminPaymentType,
    paymentterm,
    salePersonForm,
    saleOrderFormData,
    paymentReceipt
  } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([])

  useEffect(() => {
    dispatch(getARAccounts(token));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getAdminPaymentTypes(token));
    dispatch(loadSalePerson(token));
  }, [dispatch, menuId, token]);


  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    dispatch(getSaleOrderFormData(token, company_id, menuId, val, ""));
  };

  const optimizedFn = useCallback(debounce(handleChanges), []);


  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Payment #",
      dataIndex: "payment_header_no",
      key: "payment_header_no",
    },
    {
      title: "Payment Date",
      render: (text, record) => {
        return <>{moment(record.payment_date).format("MM-DD-YY")}</>
      },
    },
    {
      title: "Period",
      dataIndex: "period_name",
      key: "period_name",
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_name",
    },
    {
      title: "Payment Term",
      dataIndex: "term_name",
      key: "term_name",
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type_name",
      key: "payment_type_name",
    },
    {
      title: "Amount",
      dataIndex: "payment_amount",
      key: "payment_amount",
      align: "right",
      render: (text, record) =>{
        return <> $ {numeral(record.payment_amount).format("0,0.00")} </>
      }
    },
    {
      title: "Credit Account",
      dataIndex: "credit_account_name",
      key: "credit_account_name",
    },
    {
      title: "Debit Account",
      dataIndex: "debit_account_name",
      key: "debit_account_name",
    },
  ];

  // const handleCustomerChanges = (val) => {
  //   if (val === "" || val === 'All') return
  //   setCustomer(val);
  //   dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null)).then(
  //     (res) => {
  //       if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
  //         setCustomer(res?.payload?.data)
  //       }
  //     }
  //   );
  // };

  // function debounce(cb, delay = 1000) {
  //   let timeout
  //   return (...args) => {
  //     clearTimeout(timeout)
  //     timeout = setTimeout(() => {
  //       cb(...args)
  //     }, delay)
  //   }
  // }

  // const optimizedFn = useCallback(debounce(handleCustomerChanges), []);

  const onFinish = async () => {
    setLoading(true);
    await form.validateFields().then((values) => {
      dispatch(getPaymentReceipt(token, values)).then((res) => {
        setToogle(true);
        setLoading(false);
        setPendingSummaryList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }).catch((error) => {
      setLoading(false);
    })
  }


  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Payment #": v.payment_header_no,
          "Payment Date": moment(v.payment_date).format("MM-DD-YY"),
          "Period": v.period_name,
          "Customer": v.customer_name,
          "Gender": v.gender_name,
          "Payment Term": v.term_name,
          "Payment Type": v.payment_type_name,
          "Amount": numeral(v.payment_amount).format("0,0.00"),
          "Credit Account": v.credit_account_name,
          "Debit Account": v.debit_account_name,
        };
      })
    )
  }


  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Payment Receipt"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-0">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div className="relative">
                          <Form.Item
                            name="from_date"
                            label="From Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().startOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            name="to_date"
                            label="To Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="firstSectionRo">
                        <Form.Item
                          name="customer"
                          label="Customer"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}>
                          <Select
                            notFoundContent={
                              saleOrderFormData.loading ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Loader
                                    type="Oval"
                                    color="#3c4b58"
                                    height={20}
                                    width={20}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  No Data
                                </div>
                              )
                            }

                            autoComplete={"nope"}
                            allowClear
                            size="small"
                            showSearch
                            optionFilterProp="children"
                            loading={saleOrderFormData.loading}

                            defaultValue={form.getFieldValue().customer}
                            onSearch={(value) => {
                              optimizedFn(value);
                            }}

                          >
                            <Option value="All">All</Option>
                            {saleOrderFormData?.payload?.customerDetail.map(
                              (item) => {
                                return (
                                  <Option
                                    defaultValue={"All"}
                                    key={item.customer_id}
                                    value={item.customer_id}
                                  >
                                    {" "}
                                    {`${item.customer_code} - ${item.customer_name}`}
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="firstSectionRo">
                        <Form.Item label="Payment" name="payment_type_id" labelAlign="left" labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }} initialValue={"All"}>
                          <Select
                            size="small"
                            autoComplete={"nope"}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            className="filter-fields"
                          >
                            <Option value={null}>All</Option>
                            {adminPaymentType?.payload?.data?.map((item) => {
                              return (
                                <Option key={item.payment_type_id} value={item.payment_type_id}>
                                  {item.payment_type_name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="firstSectionRo">
                        <Form.Item label="Payment Terms" name="term" labelAlign="left" labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }} initialValue={"All"}>
                          <Select
                            size="small"
                            autoComplete={"nope"}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            className="filter-fields"
                          >
                            <Option value={null}>All</Option>
                            {paymentterm?.payload?.data.map((item) => {
                              return (
                                <Option key={item.term_id} value={item.term_id}>
                                  {item.term_name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm "
                onClick={onFinish}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {
          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            pendingSummaryList.length > 0 ? (
              <Table
                title={() =>
                  <CSVLink
                    filename={"Payment-Receipt.csv"}
                    data={excelData}
                    onClick={() => {
                    }}
                  >
                    Download Excel
                  </CSVLink>
                }
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                perPage={15}
                scroll={{
                  x: "max-content"
                }}
                dataSource={pendingSummaryList}
                columns={columns}
                loading={paymentReceipt.loading}
                pagination={{ pageSize: 3000 }}
                summary={(pageData) => {
                  let PaymentAmount = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.payment_amount
                    ));
                  },
                    0);
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right" }}>Total</p>
                          </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {PaymentAmount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            ) : <div
              style={{
                backgroundColor: "#CCCCCC",
                borderWidth: "1pt",
                borderStyle: "Dashed",
                fontWeight: "bold",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No record(s)
            </div>
          )
        }
      </div>
    </div>
  )
}

export default PaymentReceiptReport;
