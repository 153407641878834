import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Spin,
  Select,
  DatePicker,
  Checkbox,
  Form,
  Input,
  Button,
  Table,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getOmsStockSummary } from "../../redux/actions/omsStockSummary.action";
import {
  getGenderCategory,
  getGenderCategoryChange,
} from "../../redux/actions/genderCategory.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getProductClass } from "../../redux/actions/productClass.action";
import { getFitCategory } from "../../redux/actions/fitCategory.action";
import { getProductType } from "../../redux/actions/productType.action";
import { getSeason } from "../../redux/actions/season.action";
import { getGender } from "../../redux/actions/gender.action";
import { getSize } from "../../redux/actions/size.action";
import { getSku } from "../../redux/actions/sku.action";
import { ReadCookie } from "../../utils/readCookie";
import { getWashName } from "../../redux/actions/wash.action";
import { getStyle, getStyleChange } from "../../redux/actions/style";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import pdf from "../../assets/images/PDF_file_icon.svg.png";
import "../Distributor/Distributor.css";
import { Link, useHistory } from "react-router-dom";
import { Excel } from "antd-table-saveas-excel";
import Loader from "react-loader-spinner";
import numeral from "numeral";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getSeasonYear } from "../../redux/actions/seasonYear.action";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const dateFormatList = ["MM-DD-YYYY"];

const OmsStockSummary = ({ token }) => {
  TabTitle("Stock Summary");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    style,
    getWiseStockReport,
    productType,
    gender,
    genderCategory,
    season,
    productStatus,
    productClass,
    size,
    wash,
    fitCategory,
    omsStockSummary,
    genderCategoryChange,
    styleChange,
    Sku,
    seasonYear,
  } = useSelector((state) => state);
  const history = useHistory();
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const tableRef = useRef(null);
  const [productClassID, setProductClassID] = useState([]);
  const [seasonID, setSeasonID] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [isChecked2, setIsChecked2] = useState([]);
  const [allSesion, setAllSesion] = useState(true);
  const [allStatus, setAllStatus] = useState(true);
  const [isPCChecked, setIsPCChecked] = useState([]);
  const [allPC, setAllPC] = useState(true);
  const [loading, setLoading] = useState(false);
  const [BgradeCheck, setBgradeCheck] = useState(false);
  const [check, setCheck] = useState(false);
  const [data, setData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [routineLog, setRoutineLog] = useState([]);
  const location = useLocation();
  const [sortClicked, setSortClicked] = useState(false);

  const [genderName, setGenderName] = useState(null);
  const [seasonName, setSeasonName] = useState(null);
  const [yearName, setYearName] = useState(null);
  const [CatergoryName, setCatergoryName] = useState(null);
  const [sku, setSKU] = useState(null);
  const [styleName, setStyleName] = useState(null);
  const [washName, setWashName] = useState(null);

  const initialDate = moment("2026-12-31");

  let result =
    getWiseStockReport &&
    getWiseStockReport?.payload?.data?.reduce(function (r, a) {
      r[a.item_code] = r[a.item_code] || [];
      r[a.item_code].push(a);
      return r;
    }, Object.create(null));

  const wiseStockReport = result && Object.values(result);

  const values = {
    item_id: null,
    location_id: null,
  };

  const { Option } = Select;
  let pendingDate = "";

  const onChangeDate = (date, dateString) => {
    pendingDate = dateString;
  };

  const sesionSelectAll = (e) => {
    setAllSesion(e.target.checked);
  };
  const statusSelectAll = (e) => {
    setAllStatus(e.target.checked);
  };

  const isCheckboxChecked = (id, value) => {
    const filterCheckBox =
      season && season?.payload?.data.find((val) => val.season_id === id);
    const checkId = isChecked.includes(id);
    if (!checkId) {
      setIsChecked((current) => [...current, filterCheckBox.season_id]);
    }
    if (!value) {
      const removeUnCheck = isChecked.filter(
        (item) => item !== filterCheckBox.season_id
      );
      setIsChecked(removeUnCheck);
    }
    setAllSesion(false);
  };

  const isCheckboxChecked2 = (id, value) => {
    const filterCheckBox =
      productStatus &&
      productStatus?.payload?.data.find((val) => val.product_status_id === id);
    const checkId = isChecked2.includes(id);
    if (!checkId) {
      setIsChecked2((current) => [
        ...current,
        filterCheckBox.product_status_id,
      ]);
    }
    if (!value) {
      const removeUnCheck = isChecked2.filter(
        (item) => item !== filterCheckBox.product_status_id
      );
      setIsChecked2(removeUnCheck);
    }
    setAllStatus(false);
  };

  useEffect(() => {
    if (location?.state && location?.state?.values?.new_season_id) {
      const values = {
        as_on_date: initialDate,
        sku: "",
        season_id: location?.state?.values?.new_season_id,
        gender_id: location?.state?.values?.gender_id,
        gender_category_id: null,
        fit_category_id: null,
        style_id: null,
        product_status_id: null,
        product_class_id: null,
        sort_on: null,
        include_bgrade: "abc",
        wash_id: null,
        new_season_year_id: null,
      };
      form.setFieldsValue({
        season_id: values.season_id,
        gender_id: values.gender_id,
      });
      setLoading(true);
      dispatch(
        getOmsStockSummary(
          token,
          values,
          isChecked,
          isChecked2,
          isPCChecked,
          BgradeCheck
        )
      ).then((res) => {
        setLoading(false);
        setData(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }
  }, [location?.state && location?.state?.values?.new_season_id]);

  const onFinish = async (e) => {
    setLoading(true);
    await form.validateFields().then((values) => {
      dispatch(
        getOmsStockSummary(
          token,
          values,
          isChecked,
          isChecked2,
          isPCChecked,
          BgradeCheck
        )
      ).then((res) => {
        setLoading(false);
        setData(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    });
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          Sku: v.item_code,
          Inseam: v.inseam,
          Style: v.style,
          Wash: v.wash,
          "Current Season": v.current_season,
          Gender: v.gender,
          "Fit Category": v.fit_category,
          WS: Number(v.ws_price).toFixed(0),
          RTL: Number(v.retail_price).toFixed(0),
          BERGEN: v.bergan,
          TRNS: v.transit,
          PK: v.pakistan,
          PROD: v.production,
          PEND: v.pending,
          INT: v.international,
          OTS: v.ots,
          ECOM: v.ecommerce,
          NORD: v.nord,
          NEIMAN: v.neiman,
          SAKS: v.saks,
          "24S": v.tfs,
          POD: v.pod,
        };
      })
    );
  }

  // function generatePDF() {
  //   var doc = new jsPDF();

  //   var columns = ["#","SKU", "Inseam", "Style", "Wash", "Current Season", "Gender", "Fit Category", "WS", "RTL", "BERGEN", "TRNS", "PK", "PROD", "PEND", "INT", "OTS", "ECOM", "NORD", "NEIMAN", "SAKS", "24S", "POD"];
  //   var rows = [];

  //   omsStockSummary?.payload?.data.forEach((item, index) => {
  //     var row = [index + 1, item.item_code, item.inseam, item.style, item.wash, item.current_season, item.gender, item.fit_category, "$" + numeral(item.ws_price).format("0,00.00"), "$" + numeral(item.retail_price).format("0,00.00"), item.bergan, item.transit, item.pakistan, item.production, item.pending, item.international, item.ots, item.ecommerce, item.nord, item.neiman, item.saks, item.tfs, item.pod];
  //     rows.push(row);

  //   });
  //   let bergenTotal = omsStockSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.bergan), 0)
  //   let trnsTotal = omsStockSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.transit), 0)
  //   let pkTotal = omsStockSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.pakistan), 0)
  //   let prodTotal = omsStockSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.production), 0)
  //   let pendTotal = omsStockSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.pending), 0)
  //   let intTotal = omsStockSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.international), 0)
  //   let otsTotal = omsStockSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.ots), 0)
  //   let ecomTotal = omsStockSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.ecommerce), 0)
  //   let nordTotal = omsStockSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.nord), 0)
  //   let neimanTotal = omsStockSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.neiman), 0)
  //   let saksTotal = omsStockSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.saks), 0)
  //   let tfsTotal = omsStockSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.tfs), 0)
  //   let podTotal = omsStockSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.pod), 0)

  //   rows.push([" "," "," "," "," "," "," "," "," ","Total",bergenTotal.toLocaleString("en-US"), trnsTotal.toLocaleString("en-US"), pkTotal.toLocaleString("en-US"), prodTotal.toLocaleString("en-US"), pendTotal.toLocaleString("en-US"), intTotal.toLocaleString("en-US"), otsTotal.toLocaleString("en-US"), ecomTotal.toLocaleString("en-US"), nordTotal.toLocaleString("en-US"), neimanTotal.toLocaleString("en-US"), saksTotal.toLocaleString("en-US"), tfsTotal.toLocaleString("en-US"), podTotal.toLocaleString("en-US")])

  //   doc.autoTable({
  //     head: [columns],
  //     body: rows,
  //     styles: { fontSize: 3},
  //   })

  //   doc.save("Stock-Report-Summary.pdf");
  // }

  function generatePDF() {
    const input = document.getElementById("carddd"); // The ID of the element you want to capture

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("Stock-Style-Wise-Report-Summary.pdf");
    });
  }

  useEffect(() => {
    dispatch(getStyle(token, menuId));
    dispatch(getProductType(token, values));
    dispatch(getGenderCategory(token, menuId));
    dispatch(getSeason(token, menuId));
    dispatch(getProductStatus(token, menuId));
    dispatch(getProductClass(token, menuId));
    dispatch(getSize(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getFitCategory(token, menuId));
    dispatch(getWashName(token, menuId));
    dispatch(getSeasonYear(token, menuId));
  }, [dispatch, menuId, token]);

  useEffect(() => {
    if (allSesion) {
      setIsChecked([]);
    }
  }, [allSesion]);

  useEffect(() => {
    if (allStatus) {
      setIsChecked2([]);
    }
  }, [allStatus]);

  useEffect(() => {
    if (allPC) {
      setIsPCChecked([]);
    }
  }, [allPC]);

  const handlePrint = () => {
    window.print();
  };


  const columns = [
    {
      title: "#",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "SKU",
      dataIndex: "item_code",
      key: "item_code",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.item_code !== null) {
          return record.item_code
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.item_code !== null) & (b.item_code !== null)) {
          return a.item_code - b.item_code;
        }
      },
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
      key: "inseam",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.inseam !== null) {
          return record.inseam
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.inseam !== null) & (b.inseam !== null)) {
          return a.inseam - b.inseam;
        }
      },
    },
    {
      title: "Style",
      dataIndex: "style",
      key: "style",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.style !== null) {
          return record.style
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.style !== null) & (b.style !== null)) {
          return a.style.localeCompare(b.style);
        }
      },
    },
    {
      title: "Wash",
      dataIndex: "wash",
      key: "wash",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.wash !== null) {
          return record.wash
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.wash !== null) & (b.wash !== null)) {
          return a.wash.localeCompare(b.wash);
        }
      },
    },
    {
      title: "Current Season",
      dataIndex: "current_season",
      key: "current_season",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.current_season !== null) {
          return record.current_season
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.current_season !== null) & (b.current_season !== null)) {
          return a.current_season.localeCompare(b.current_season);
        }
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.gender !== null) {
          return record.gender
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.gender !== null) & (b.gender !== null)) {
          return a.gender.localeCompare(b.gender);
        }
      },
    },
    {
      title: "Fit Category",
      dataIndex: "fit_category",
      key: "fit_category",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.fit_category !== null) {
          return record.fit_category
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.fit_category !== null) & (b.fit_category !== null)) {
          return a.fit_category.localeCompare(b.fit_category);
        }
      },
    },
    {
      title: "WS",
      dataIndex: "ws_price",
      key: "ws_price",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>;
      },
      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.ws_price !== null) {
          return record.ws_price
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.ws_price !== null) & (b.ws_price !== null)) {
          return a.ws_price - b.ws_price;
        }
      },
    },
    {
      title: "RTL",
      dataIndex: "retail_price",
      key: "retail_price",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>;
      },
      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.retail_price !== null) {
          return record.retail_price
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.retail_price !== null) & (b.retail_price !== null)) {
          return a.retail_price - b.retail_price;
        }
      },
    },
    {
      title: "BERGEN",
      dataIndex: "bergan",
      key: "bergan",

      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.bergan !== null) {
          return record.bergan
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.bergan !== null) & (b.bergan !== null)) {
          return a.bergan - b.bergan;
        }
      },
    },
    {
      title: "TRNS",
      dataIndex: "transit",
      key: "transit",

      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.transit !== null) {
          return record.transit
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.transit !== null) & (b.transit !== null)) {
          return a.transit - b.transit;
        }
      },
    },
    {
      title: "PK",
      dataIndex: "pakistan",
      key: "pakistan",

      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.pakistan !== null) {
          return record.pakistan
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.pakistan !== null) & (b.pakistan !== null)) {
          return a.pakistan - b.pakistan;
        }
      },
    },
    {
      title: "PROD",
      dataIndex: "production",
      key: "production",

      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.production !== null) {
          return record.production
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.production !== null) & (b.production !== null)) {
          return a.production - b.production;
        }
      },
    },
    {
      title: "PEND",
      dataIndex: "pending",
      key: "pending",

      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.pending !== null) {
          return record.pending
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.pending !== null) & (b.pending !== null)) {
          return a.pending - b.pending;
        }
      },
    },
    {
      title: "INT",
      dataIndex: "international",
      key: "international",

      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.international !== null) {
          return record.international
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.international !== null) & (b.international !== null)) {
          return a.international - b.international;
        }
      },
    },
    {
      title: "OTS",
      dataIndex: "ots",
      key: "ots",

      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.ots !== null) {
          return record.ots
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.ots !== null) & (b.ots !== null)) {
          return a.ots - b.ots;
        }
      },
    },
    {
      title: "ECOM",
      dataIndex: "ecommerce",
      key: "ecommerce",

      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.ecommerce !== null) {
          return record.ecommerce
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.ecommerce !== null) & (b.ecommerce !== null)) {
          return a.ecommerce - b.ecommerce;
        }
      },
    },
    {
      title: "NORD",
      dataIndex: "nord",
      key: "nord",

      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.nord !== null) {
          return record.nord
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.nord !== null) & (b.nord !== null)) {
          return a.nord - b.nord;
        }
      },
    },
    {
      title: "NEIMAN",
      dataIndex: "neiman",
      key: "neiman",

      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.neiman !== null) {
          return record.neiman
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.neiman !== null) & (b.neiman !== null)) {
          return a.neiman - b.neiman;
        }
      },
    },
    {
      title: "SAKS",
      dataIndex: "saks",
      key: "saks",
      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.saks !== null) {
          return record.saks
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.saks !== null) & (b.saks !== null)) {
          return a.saks - b.saks;
        }
      },
    },
    {
      title: "24S",
      dataIndex: "tfs",
      key: "tfs",
      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.tfs !== null) {
          return record.tfs
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.tfs !== null) & (b.tfs !== null)) {
          return a.tfs - b.tfs;
        }
      },
    },
    {
      title: "POD",
      dataIndex: "pod",
      key: "pod",
      align: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220 w-100"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.pod !== null) {
          return record.pod
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.pod !== null) & (b.pod !== null)) {
          return a.pod - b.pod;
        }
      },
    },
  ];

  const bergenTotal = omsStockSummary?.payload?.data.reduce(
    (acc, item, index) => acc + parseFloat(item.bergan),
    0
  );
  const trnsTotal = omsStockSummary?.payload?.data.reduce(
    (acc, item, index) => acc + parseFloat(item.transit),
    0
  );
  const pkTotal = omsStockSummary?.payload?.data.reduce(
    (acc, item, index) => acc + parseFloat(item.pakistan),
    0
  );
  const prodTotal = omsStockSummary?.payload?.data.reduce(
    (acc, item, index) => acc + parseFloat(item.production),
    0
  );
  const pendTotal = omsStockSummary?.payload?.data.reduce(
    (acc, item, index) => acc + parseFloat(item.pending),
    0
  );
  const intTotal = omsStockSummary?.payload?.data.reduce(
    (acc, item, index) => acc + parseFloat(item.international),
    0
  );
  const otsTotal = omsStockSummary?.payload?.data.reduce(
    (acc, item, index) => acc + parseFloat(item.ots),
    0
  );
  const ecomTotal = omsStockSummary?.payload?.data.reduce(
    (acc, item, index) => acc + parseFloat(item.ecommerce),
    0
  );
  const nordTotal = omsStockSummary?.payload?.data.reduce(
    (acc, item, index) => acc + parseFloat(item.nord),
    0
  );
  const neimanTotal = omsStockSummary?.payload?.data.reduce(
    (acc, item, index) => acc + parseFloat(item.neiman),
    0
  );
  const saksTotal = omsStockSummary?.payload?.data.reduce(
    (acc, item, index) => acc + parseFloat(item.saks),
    0
  );
  const tfsTotal = omsStockSummary?.payload?.data.reduce(
    (acc, item, index) => acc + parseFloat(item.tfs),
    0
  );
  const podTotal = omsStockSummary?.payload?.data.reduce(
    (acc, item, index) => acc + parseFloat(item.pod),
    0
  );

  const handleGender = (gender_id) => {
    dispatch(getGenderCategoryChange(token, gender_id));
    dispatch(getStyleChange(token, gender_id, null, null));
    form.setFieldsValue({
      gender_category_id: "All",
      style_id: "All",
      sku: "All",
    });
  };

  const handleStyle = (gender_category_id) => {
    dispatch(
      getStyleChange(
        token,
        form.getFieldValue().gender_id,
        gender_category_id,
        null
      )
    );
    form.setFieldsValue({
      style_id: "All",
      sku: "All",
    });
  };

  const handleSku = (sku_id) => {
    if (sku_id === null) {
      dispatch(getSku(token, null, null, null));
    } else {
      dispatch(
        getSku(
          token,
          form.getFieldValue().gender_id,
          form.getFieldValue().gender_category_id,
          sku_id
        )
      );
    }
    form.setFieldsValue({
      sku: "All",
    });
  };

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleSkuSearch = () => {
    dispatch(getSku(token, null, null, null));
  };

  const optimizedFn = useCallback(debounce(handleSkuSearch), []);

  const filterProductStatusName = productStatus?.payload?.data?.filter(item => isChecked2.includes(item.product_status_id));


  return (
    <div className="header_class business_header_oms" id="card-pdf" >
      <div
        style={{
          width: "100%",
          height: "100vh",
          position: "absolute",
          top: 0,
          left: -10000,
          // left: 0,
          zIndex: 1001,
        }}
      >
        <div className="header_class business_header_oms" id="carddd">
          <div className="row justify-content-between business_class_oms distributor" style={{ width: "100%" }}>
            <div className="col-3 d-flex align-items-start box-space">
              <div className="logobrand">
                <img id="ImgLogo" alt="logo" src={logo} />
              </div>
            </div>
            <div className="col-4 box-space right-content-header text-right">
              <div className=" d-flex justify-content-end">
                <span style={{ fontSize: "small", fontWeight: "bolder" }}>
                  Stock Report Summary
                  <br />
                  Rpt # 489
                  <br />
                  <p className=" mb-0">Rpt # 001_V4</p>
                  Login as:{" "}
                  <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                    {userName}
                  </span>
                  <br />
                </span>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: 20 }}>
            {genderName && <div>Gender: {genderName}</div>}
            {seasonName && <div>Season: {seasonName}</div>}
            {yearName && <div>Season Year: {yearName}</div>}
            {CatergoryName && <div>Category: {CatergoryName}</div>}
            {sku && <div>Sku: {sku}</div>}
            {styleName && <div>Style: {styleName}</div>}
            {washName && <div>Wash: {washName}</div>}
            {
              isChecked2.length > 0 && <div>
                Product Status : {filterProductStatusName && filterProductStatusName.map((val, key) => {
                  return <span key={key}>{val.product_status_name} </span>
                })}
              </div>
            }
            <div>Pending Till: {moment(form.getFieldValue().as_on_date).format("MM-DD-YYYY")}</div>
          </div>
          <Table
            className=" border-line-th  bottom-align-th  table-td-border-right  th-general-style  
              td-general-style even-tr-style border font_size_td font_size_th"
            ref={tableRef}
            size="small"
            bordered={false}

            type="company"
            headerClassName="abc"
            dataSource={data && data}
            columns={columns}
            scroll={{ x: "max-content", y: "max-content", }}
            loading={omsStockSummary.loading}
            pagination={{
              defaultCurrent: 1,
              defaultPageSize: 500,
              pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000, 9000, 15000],
            }}
            summary={(pageData) => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <p style={{ textAlign: "center", margin: "0" }}>
                        Total &gt; &gt; &gt;
                      </p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <p
                        style={{ textAlign: "right", margin: "0" }}
                        className="totalquantity"
                      >
                        {bergenTotal?.toLocaleString("en-US")}
                      </p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <p
                        style={{ textAlign: "right", margin: "0" }}
                        className="totalquantity"
                      >
                        {trnsTotal?.toLocaleString("en-US")}
                      </p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <p
                        style={{ textAlign: "right", margin: "0" }}
                        className="totalquantity"
                      >
                        {pkTotal?.toLocaleString("en-US")}
                      </p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <p
                        style={{ textAlign: "right", margin: "0" }}
                        className="totalquantity"
                      >
                        {prodTotal?.toLocaleString("en-US")}
                      </p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <p
                        style={{ textAlign: "right", margin: "0" }}
                        className="totalquantity"
                      >
                        {pendTotal?.toLocaleString("en-US")}
                      </p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <p
                        style={{ textAlign: "right", margin: "0" }}
                        className="totalquantity"
                      >
                        {intTotal?.toLocaleString("en-US")}
                      </p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <p
                        style={{ textAlign: "right", margin: "0" }}
                        className="totalquantity"
                      >
                        {otsTotal?.toLocaleString("en-US")}
                      </p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <p
                        style={{ textAlign: "right", margin: "0" }}
                        className="totalquantity"
                      >
                        {ecomTotal?.toLocaleString("en-US")}
                      </p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <p
                        style={{ textAlign: "right", margin: "0" }}
                        className="totalquantity"
                      >
                        {nordTotal?.toLocaleString("en-US")}
                      </p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <p
                        style={{ textAlign: "right", margin: "0" }}
                        className="totalquantity"
                      >
                        {neimanTotal?.toLocaleString("en-US")}
                      </p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <p
                        style={{ textAlign: "right", margin: "0" }}
                        className="totalquantity"
                      >
                        {saksTotal?.toLocaleString("en-US")}
                      </p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <p
                        style={{ textAlign: "right", margin: "0" }}
                        className="totalquantity"
                      >
                        {tfsTotal?.toLocaleString("en-US")}
                      </p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <p
                        style={{ textAlign: "right", margin: "0" }}
                        className="totalquantity"
                      >
                        {podTotal?.toLocaleString("en-US")}
                      </p>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      </div>
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        {/* <div className="col-sm-6 box-space pl-sm-0">
                    <div className="brand-para">
                        <div>DL1961 Premium Denim Inc.</div>
                        <div>121 Varick Street</div>
                        <div>4th Floor</div>
                        <div>New York, NY 10013</div>
                        <div>(P) 646-514-9736</div>
                        <div>(F) 212-991-9989</div>
                        <div>(E) sales@DL1961.com</div>
                    </div>
                </div> */}
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8 printing_class">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class">
                <CSVLink
                  filename={"Oms-Stock-Summary.csv"}
                  data={excelData}
                  style={{ display: "flex" }}
                  onClick={() => { }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                </CSVLink>
              </div>
              <div className="print_icon printing_class">
                <input
                  type="image"
                  name="ImgPDF"
                  alt="Export to PDF"
                  id="ImgPDF"
                  title="Export to PDF"
                  src={pdf}
                  onClick={generatePDF}
                  style={{ borderWidth: "0px", height: "18px" }}
                />
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Stock Report Summary
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
              <br />
            </span>
          </div>
          {/* Last Updated By:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {moment(routineLog[0]?.r_date +"T"+ routineLog[0]?.r_time).format('MM-DD-YYYY, hh:mm a')}
              </span> */}
        </div>
      </div>

      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              <hr className="my-1" />
              <div>
                <label htmlFor="">Product Status</label>
                <div className="checkboxes">
                  <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox checked={allStatus} onChange={statusSelectAll}>
                        All
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {productStatus &&
                    productStatus?.payload?.data.map((it, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="current_status_id">
                            <Checkbox
                              checked={
                                allStatus
                                  ? false
                                  : isChecked2.includes(it.product_status_id)
                              }
                              onChange={(e) =>
                                isCheckboxChecked2(
                                  it.product_status_id,
                                  e.target.checked
                                )
                              }
                            >
                              {it.product_status_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div>
              <hr className="my-1" />
              <div className="main_form row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Gender
                    </label>
                    <div className="w-100">
                      <Form.Item name="gender_id" initialValue={"All"}>
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterSort={(optionA, optionB) =>
                            optionB?.value !== null
                              ? optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                              : ""
                          }
                          onChange={(e, obj) => setGenderName(obj.children)}
                        >
                          <Option value={null}>All</Option>
                          {gender &&
                            gender?.payload?.data.map((it, index) => {
                              return (
                                <Option key={index} value={it.gender_id}>
                                  {it.gender_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Season
                    </label>
                    <div className=" w-100">
                      <Form.Item name="season_id" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().season_id}
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionB?.value !== null
                              ? optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                              : ""
                          }
                          onChange={(e, obj) => setSeasonName(obj.children)}
                        >
                          <Option value={null}>All</Option>
                          {season &&
                            season?.payload?.data?.map((status, index) => {
                              return (
                                <Option key={index} value={status.season_id}>
                                  {status.season_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Season Year
                    </label>
                    <div className="w-100">
                      <Form.Item name="new_season_year_id" initialValue={"All"}>
                        <Select
                          className={"select_Inputs"}
                          defaultValue={form.getFieldValue().new_season_year_id}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionB?.value !== "All"
                              ? optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                              : ""
                          }
                          onChange={(e, obj) => setYearName(obj.children)}
                        >
                          <Option value={"All"}>All</Option>
                          {seasonYear?.payload?.data?.map((res) => (
                            <Option
                              key={res.season_year_id}
                              value={res.season_year_id}
                            >
                              {res.season_year}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/*Second*/}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Category
                    </label>
                    <div className="w-100">
                      <Form.Item initialValue={"All"} name="gender_category_id">
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          onChange={(e, obj) => setCatergoryName(obj.children)}
                        //   filterSort={(optionA, optionB) =>
                        //     optionB?.value!==null?
                        //       optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        // }
                        >
                          <Option value={null}>All</Option>
                          {genderCategoryChange &&
                            genderCategoryChange?.payload?.data.length >= 0
                            ? genderCategoryChange &&
                            genderCategoryChange?.payload?.data.map(
                              (it, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={it.gender_category_id}
                                  >
                                    {it.gender_category_name}
                                  </Option>
                                );
                              }
                            )
                            : genderCategory &&
                            genderCategory?.payload?.data.map((it, index) => {
                              return (
                                <Option
                                  key={index}
                                  value={it.gender_category_id}
                                >
                                  {it.gender_category_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main_form row form-margin">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Sku
                    </label>
                    <div className="w-100">
                      <Form.Item name="sku" initialValue={"All"}>
                        <Select
                          notFoundContent={
                            Sku.loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Loader
                                  type="Oval"
                                  color="#3c4b58"
                                  height={20}
                                  width={20}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                No Data
                              </div>
                            )
                          }
                          className={"select_Inputs"}
                          showSearch
                          onSearch={(value) => {
                            optimizedFn(value);
                          }}
                          optionFilterProp="children"
                          size="small"
                          onChange={(e, obj) => setSKU(obj.children)}
                        >
                          <Option value={null}>All</Option>
                          {Sku &&
                            Sku?.payload?.data.map((it, index) => {
                              return (
                                <Option key={index} value={it.sku}>
                                  {it.sku}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Style
                    </label>
                    <div className="w-100">
                      <Form.Item initialValue={"All"} name="style_id">
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          filterSort={(optionA, optionB) =>
                            optionB?.value !== null
                              ? optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                              : ""
                          }
                          size="small"
                          onChange={(e, obj) => setStyleName(obj.children)}

                        >
                          <Option value={null}>All</Option>
                          {styleChange && styleChange?.payload?.data.length >= 0
                            ? styleChange &&
                            styleChange?.payload?.data.map((style, index) => {
                              return (
                                <Option key={index} value={style.style_id}>
                                  {style.style_name}
                                </Option>
                              );
                            })
                            : style &&
                            style?.payload?.data.map((style, index) => {
                              return (
                                <Option key={index} value={style.style_id}>
                                  {style.style_name}
                                </Option>
                              );
                            })}
                          {/* {style &&
                            style?.payload?.data.map((style, index) => {
                              return (
                                <Option key={index} value={style.style_id}>
                                  {style.style_name}
                                </Option>
                              );
                            })} */}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Wash
                    </label>
                    <div className="w-100">
                      <Form.Item name="wash_id" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().wash_id}
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          onChange={(e, obj) => setWashName(obj.children)}
                        >
                          <Option value={null}>All</Option>
                          {wash &&
                            wash?.payload?.data?.map((status, index) => {
                              return (
                                <Option key={index} value={status.wash_id}>
                                  {status.wash_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">
                      Pending Till
                    </label>
                    <div className="w-100">
                      <Form.Item
                        name="as_on_date"
                        className="full_date"
                        initialValue={initialDate}
                      >
                        <DatePicker size="small" format={dateFormatList} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "3px",
                  float: "right",
                }}
              >
                {/* <label className=" no_visibility">none</label> */}
                <Button
                  style={{ width: "fit-content" }}
                  type="primary"
                  htmlType="submit"
                  className="submit-btn-btm m-0"
                >
                  Generate
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div>
        <div
        // style={{
        //   overflowY: "scroll",
        //   height: "57vh",

        // }}
        >
          {omsStockSummary?.payload?.data !== undefined ? (
            data.length > 0 ? (
              <div id="card">
                <div style={{ display: "flex", gap: 20 }}>
                  {genderName && <div>Gender: {genderName}</div>}
                  {seasonName && <div>Season: {seasonName}</div>}
                  {yearName && <div>Season Year: {yearName}</div>}
                  {CatergoryName && <div>Category: {CatergoryName}</div>}
                  {sku && <div>Sku: {sku}</div>}
                  {styleName && <div>Style: {styleName}</div>}
                  {washName && <div>Wash: {washName}</div>}
                  {
                    isChecked2.length > 0 && <div>
                      Product Status : {filterProductStatusName && filterProductStatusName.map((val, key) => {
                        return <span key={key}>{val.product_status_name} </span>
                      })}
                    </div>
                  }

                  <div>Pending Till: {moment(form.getFieldValue().as_on_date).format("MM-DD-YYYY")}</div>
                </div>
                <Table
                  className=" border-line-th  bottom-align-th  table-td-border-right  th-general-style  
              td-general-style even-tr-style border "
                  ref={tableRef}
                  size="small"
                  bordered={false}
                  type="company"
                  headerClassName="abc"
                  dataSource={data && data}
                  columns={columns}
                  scroll={{ x: "max-content" }}
                  loading={omsStockSummary.loading}
                  pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: 500,
                    pageSizeOptions: [
                      100, 500, 1000, 2000, 2500, 3000, 9000, 15000,
                    ],
                  }}
                  summary={(pageData) => {
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "center", margin: "0" }}>
                              Total &gt; &gt; &gt;
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p
                              style={{ textAlign: "right", margin: "0" }}
                              className="totalquantity"
                            >
                              {bergenTotal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p
                              style={{ textAlign: "right", margin: "0" }}
                              className="totalquantity"
                            >
                              {trnsTotal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p
                              style={{ textAlign: "right", margin: "0" }}
                              className="totalquantity"
                            >
                              {pkTotal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p
                              style={{ textAlign: "right", margin: "0" }}
                              className="totalquantity"
                            >
                              {prodTotal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p
                              style={{ textAlign: "right", margin: "0" }}
                              className="totalquantity"
                            >
                              {pendTotal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p
                              style={{ textAlign: "right", margin: "0" }}
                              className="totalquantity"
                            >
                              {intTotal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p
                              style={{ textAlign: "right", margin: "0" }}
                              className="totalquantity"
                            >
                              {otsTotal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p
                              style={{ textAlign: "right", margin: "0" }}
                              className="totalquantity"
                            >
                              {ecomTotal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p
                              style={{ textAlign: "right", margin: "0" }}
                              className="totalquantity"
                            >
                              {nordTotal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p
                              style={{ textAlign: "right", margin: "0" }}
                              className="totalquantity"
                            >
                              {neimanTotal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p
                              style={{ textAlign: "right", margin: "0" }}
                              className="totalquantity"
                            >
                              {saksTotal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p
                              style={{ textAlign: "right", margin: "0" }}
                              className="totalquantity"
                            >
                              {tfsTotal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p
                              style={{ textAlign: "right", margin: "0" }}
                              className="totalquantity"
                            >
                              {podTotal.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </div>
            ) : (
              <div className="no-Data">
                {/* {} */}
                <h1>No Data</h1>
              </div>
            )
          ) : (
            loading && (
              <div className="loader" style={{ marginTop: "20px" }}>
                <Spin size="medium" />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default OmsStockSummary;
