import React, { useCallback, useEffect, useState } from "react";
import { Spin, Select, DatePicker, Checkbox, Form, Input, Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getGenderCategory, getGenderCategoryChange } from "../../redux/actions/genderCategory.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getProductClass } from "../../redux/actions/productClass.action";
import { getFitCategory } from "../../redux/actions/fitCategory.action";
import { getSeason } from "../../redux/actions/season.action";
import { getGender } from "../../redux/actions/gender.action";
import { getSize } from "../../redux/actions/size.action";
import { getSku } from "../../redux/actions/sku.action"
import { ReadCookie } from "../../utils/readCookie";
import { getStyle, getStyleChange } from "../../redux/actions/style";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import { Excel } from "antd-table-saveas-excel";
import Loader from "react-loader-spinner";
import { getWashName } from "../../redux/actions/wash.action";
import { useRef } from "react";
import { DownloadTableExcel, useDownloadExcel } from "react-export-table-to-excel";
import { productionReport } from "../../redux/actions/productionReport.action";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { productionFinish } from "../../redux/actions/productionFinished.action";
const { Option } = Select;


const ProductionFinished = ({ token }) => {
    TabTitle("Production")
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {
        style,
        gender,
        season,
        omsStockSummary,
        genderCategoryChange,
        styleChange,
        Sku,
        wash,
        production,
        production_finished
    } = useSelector((state) => state);
    const menuId = ReadCookie("menuId");
    const userName = ReadCookie("name");
    const tableRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [productionList, setProductionList] = useState([]);
    const [excelData, setExcelData] = useState([])

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }


    // const onFinish = async (e) => {
    //     setLoading(true)
    //     await form.validateFields().then((data) => {
    //         dispatch(productionFinish(token, data)).then((res => {
    //             setLoading(false)
    //             setProductionList(res?.payload?.data);
    //             downloadExcelFileData(res?.payload?.data);
    //         }));
    //     });
    // };

    useEffect(() => {
        setLoading(true)
        dispatch(getStyle(token, menuId));
        dispatch(getSeason(token, menuId));
        dispatch(getProductClass(token, menuId));
        dispatch(getSize(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getWashName(token, menuId));
        dispatch(getFitCategory(token, menuId));

        dispatch(productionFinish(token)).then((res => {
            setLoading(false)
            setProductionList(res?.payload?.data);
            downloadExcelFileData(res?.payload?.data);
        }));
    }, [dispatch, menuId, token]);

    const handlePrint = () => {
        window.print();
    };


    const colums = [
        {
            title: 'S#',
            dataIndex: 'index',
            key: 'index',
            width: 40,
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "Bulk Order Id",
            dataIndex: "bulk_order_id",
            key: "bulk_order_id",
            align: "center",
            // width: 80,
        },
        {
            title: "Cut Date",
            dataIndex: "cut_date",
            key: "cut_date",
            // width: 50,
            align: "center",
            render: (text, record) => {
                return <>{moment(record.cut_date).format("MM-DD-YY")}</>
            },

        },
        {
            title: "Pack Date",
            dataIndex: "pack_date",
            key: "pack_date",
            align: "center",
            render: (text, record) => {
                return <>{moment(record.pack_date).format("MM-DD-YY")}</>
            },
            // width: 70,
        },
        {
            title: "Ex Factory Date",
            dataIndex: "exfactory_date",
            key: "exfactory_date",
            align: "center",
            render: (text, record) => {
                return <>{moment(record.exfactory_date).format("MM-DD-YY")}</>
            },
        },
        {
            title: "Extension Date",
            dataIndex: "extension_date",
            key: "extension_date",
            align: "center",
            render: (text, record) => {
                return <>{moment(record.extension_date).format("MM-DD-YY")}</>
            },
        },
        {
            title: "Type Category",
            dataIndex: "type_category",
            key: "type_category",
            // width: 70,
        },
        {
            title: "Product Code",
            dataIndex: "product_code",
            key: "product_code",
            // width: 100,
            // render: (text, record) => {
            //     return <>{moment(record.po_date).format("MM-DD-YY")}</>
            // },
        },
        {
            title: "Style",
            dataIndex: "style",
            key: "style",
            // width: 70,
        },
        {
            title: "Wash Type",
            dataIndex: "wash_type",
            key: "wash_type",
            // width: 70,
        },



        {
            title: "Fabric",
            dataIndex: "fabric",
            key: "fabric",
            // width: 60,
        },
        {
            title: "Fabric Group",
            dataIndex: "fabric_group",
            key: "fabric_group",
            // width: 80,
        },


        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            align: "right",
            // width: 70,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            // width: 70,
        },
    ];

  
    function debounce(cb, delay = 1000) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }



    const handleSkuSearch = () => {
        dispatch(getSku(token, null, null, null));
    }

    const optimizedFn = useCallback(debounce(handleSkuSearch), []);


    function downloadExcelFileData(data) {
        setExcelData(
            data.map((v) => {
                return {
                    "Bulk Order Id": v.bulk_order_id,
                    "Cut Date": moment(v.cut_date).format("MM-DD-YY"),
                    "Pack Date": moment(v.pack_date).format("MM-DD-YY"),
                    "Exfactory Date": moment(v.exfactory_date).format("MM-DD-YY"),
                    "Extension Date": moment(v.extension_date).format("MM-DD-YY"),
                    "Type Category": v.type_category,
                    "Product Code": v.product_code,
                    "Style": v.style,
                    "Wash Type": v.wash_type,
                    "Fabric": v.fabric,
                    "Fabric Group": v.fabric_group,
                    "Quantity": v.quantity,
                    "Status": v.status,
                };
            })
        )
    }

    const totalQuantity = () => {
        let sumQty = productionList.reduce(function (previousValue, currentValue) {
            return previousValue += Number(currentValue.quantity);
        }, 0);
        return sumQty;
    }


    return (
        <div className="header_class business_header_oms intransit">
            <div className="row justify-content-between business_class_oms distributor">
                <div className="col-3 d-flex align-items-start box-space">
                    <div className="logobrand">
                        <img id="ImgLogo" alt="logo" src={logo} />
                    </div>
                </div>
                <div className="col-4 box-space right-content-header text-right">
                    <div className=" d-flex justify-content-end">
                        <div className=" d-flex justify-content-end mr-8">
                            <div className="print_icon printing_class">
                                <i
                                    onClick={handlePrint}
                                    id="Image1"
                                    title="Print"
                                    alt="Print"
                                    src={btnPrint}
                                    className="fa-solid fa-print"
                                ></i>
                            </div>
                            <div className="print_icon printing_class">
                                <CSVLink
                                    filename={"Production-Report.csv"}
                                    data={excelData}
                                    onClick={() => {
                                    }}
                                >
                                    <input
                                        type="image"
                                        name="ImgExcel"
                                        alt="Export to excel"
                                        id="ImgExcel"
                                        title="Export to excel"
                                        src={xlsx}
                                    />
                                </CSVLink>

                            </div>
                        </div>
                        <span style={{ fontSize: "small", fontWeight: "bolder" }}>
                            Production Finished
                            <br />
                            Rpt # 489
                            <br />
                            <p className=" mb-0">Rpt # 001_V4</p>
                            Login as:{" "}
                            <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                                {userName}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            {/* <div className="business_class_oms mb-2 distributor">
                <Form form={form} name="control-hooks" onFinish={onFinish}>
                    <div className="main_form row">
                        <div className="col-12">
                            <hr className="my-1" />
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44" htmlFor="">Gender</label>
                                        <div className="w-100">
                                            <Form.Item name="gender_id" initialValue={"All"}>
                                                <Select
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    size="small"
                                                    onChange={(e) => handleGender(e)}
                                                    filterSort={(optionA, optionB) =>
                                                        optionB?.value !== null ?
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                    }
                                                >
                                                    <Option value={null}>All</Option>
                                                    {gender &&
                                                        gender?.payload?.data.map((it, index) => {
                                                            return (
                                                                <Option key={index} value={it.gender_id}>
                                                                    {it.gender_name}
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44" htmlFor="">Wash</label>
                                        <div className="w-100">
                                            <Form.Item name="wash_id" initialValue={"All"}>
                                                <Select
                                                    defaultValue={form.getFieldValue().wash_id}
                                                    className={"select_Inputs"}
                                                    showSearch

                                                    optionFilterProp="children"
                                                    size="small"
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .includes(input.toLowerCase())
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionB?.value !== null ?
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                    }
                                                >
                                                    <Option value={null}>All</Option>
                                                    {wash &&
                                                        wash?.payload?.data?.map(
                                                            (status, index) => {
                                                                return (
                                                                    <Option
                                                                        key={status.wash_id}
                                                                        value={status.wash_id}
                                                                    >
                                                                        {status.wash_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44" htmlFor="">Style</label>
                                        <div className="w-100">
                                            <Form.Item initialValue={"All"} name="style_id">
                                                <Select
                                                    className={"select_Inputs"}
                                                    showSearch

                                                    optionFilterProp="children"

                                                    size="small"
                                                    onChange={(e) => handleSku(e)}
                                                    filterSort={(optionA, optionB) =>
                                                        optionB?.value !== null ?
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                    }
                                                >
                                                    <Option value={null}>All</Option>
                                                    {
                                                        styleChange && styleChange?.payload?.data.length >= 0 ? (
                                                            styleChange && styleChange?.payload?.data.map((style, index) => {
                                                                return (
                                                                    <Option key={index} value={style.style_id}>
                                                                        {style.style_name}
                                                                    </Option>
                                                                );
                                                            })
                                                        ) : (
                                                            style && style?.payload?.data.map((style, index) => {
                                                                return (
                                                                    <Option key={index} value={style.style_id}>
                                                                        {style.style_name}
                                                                    </Option>
                                                                );
                                                            })
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44" htmlFor="">Sku</label>
                                        <div className="w-100">
                                            <Form.Item name="sku" initialValue={"All"}>
                                                <Select
                                                    notFoundContent={Sku.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <Loader type="Oval" color="#3c4b58" height={20} width={20} />
                                                    </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    onSearch={(value) => {
                                                        optimizedFn(value)
                                                    }}

                                                    optionFilterProp="children"
                                                    size="small"
                                                >
                                                    <Option value={null}>All</Option>
                                                    {Sku &&
                                                        Sku?.payload?.data.map((it, index) => {
                                                            return (

                                                                <Option key={index} value={it.sku}>
                                                                    {it.sku}
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main_form row form-margin">
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width44" htmlFor="">Season</label>
                                        <div className="w-100">
                                            <Form.Item name="current_season_id" initialValue={"All"}>
                                                <Select
                                                    defaultValue={form.getFieldValue().current_season_id}
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    size="small"
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .includes(input.toLowerCase())
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionB?.value !== null ?
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                                    }
                                                >
                                                    <Option value={null}>All</Option>
                                                    {season &&
                                                        season?.payload?.data?.map(
                                                            (status, index) => {
                                                                return (
                                                                    <Option
                                                                        key={status.season_id}
                                                                        value={status.season_id}
                                                                    >
                                                                        {status.season_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <div className="w-100" style={{ marginTop: "4px" }}>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                className="submit-btn-btm m-0 "
                                            >
                                                Generate
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div> */}
            <div
                className="table_content international_order"

            // style={{ maxWidth: "1400px" }}
            >
                <div className="overflow"  >
                    {

                    }
                    {production_finished?.payload?.data !== undefined ?
                        (
                            production_finished?.payload?.data.length > 0 ? (
                                <Table
                                    className="fix-scroll-table  table-header-sticky  border-line-th  bottom-align-th  table-td-border-right  th-general-style  
              td-general-style even-tr-style border"
                                    ref={tableRef}
                                    size="small"
                                    bordered={false}
                                    type="company"
                                    headerClassName='abc'
                                    dataSource={productionList}
                                    columns={colums}
                                    loading={production_finished.loading}
                                    pagination={{ pageSize: 500 }}
                                    scroll={{ x: 'max-content' }}
                                    summary={(pageData) => {

                                        let Quantity = pageData.reduce(function (
                                            previousValue,
                                            currentValue
                                        ) {
                                            return (previousValue += parseFloat(
                                                currentValue.quantity
                                            ));
                                        },
                                            0);
                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell>
                                                        <p style={{ textAlign: "right" }} className="totalquantity">
                                                            Page Total {Quantity.toLocaleString("en-US")}
                                                        </p>
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </>
                                        );
                                    }}
                                />
                            ) : (
                                <div className="no-Data">
                                    {/* {} */}
                                    <h1>No Data</h1>
                                </div>
                            )
                        ) : (
                            loading && (
                                <div className="loader" style={{ marginTop: "20px" }}>
                                    <Spin size="medium" />
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default ProductionFinished;
