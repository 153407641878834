import {
  GET_DROPSHIP_SUMMARY_REPORTS_REQUEST,
  GET_DROPSHIP_SUMMARY_REPORTS_SUCCESS,
  GET_DROPSHIP_SUMMARY_REPORTS_ERROR
} from "../actions/types";


export const dropshipSummary = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DROPSHIP_SUMMARY_REPORTS_REQUEST:
      return {
        loading: true,
      };
    case GET_DROPSHIP_SUMMARY_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_DROPSHIP_SUMMARY_REPORTS_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default: {
      return state;
    }
  }
};
