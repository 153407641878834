import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button, Form, Input, Select, Collapse, Row, Col, Table } from "antd";
import { FilterFilled, SearchOutlined, FilterOutlined, CaretRightOutlined } from "@ant-design/icons";
import { loadCustomersList } from "../../redux/actions/customerList";
import { Link } from "react-router-dom";
import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { loadFreightTerm } from "../../redux/actions/freightTerm";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { loadBergenBillingOption } from "../../redux/actions/bergenBillingOption";
import { loadShippingMethod } from "../../redux/actions/shippingMethod";
import { loadOrderStatus } from "../../redux/actions/customerSection";
import { getCustomerTerm } from "../../redux/actions/customerTerm.action";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { ReadCookie } from "../../utils/readCookie";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
export default function CustomerNewList({ token }) {
  TabTitle("Customer Information")
  const dispatch = useDispatch();
  const { ShippingMethod, FreightTerm, customerForms,customerList,
    customerTypes, customerSections,
    salePerson, region, paymentterm } = useSelector((state) => state);
  const { Panel } = Collapse;
  const [dataLen, setDataLen] = useState(0);
  const [show, setShow] = useState(false);
  const [customerAllId, setCustomerAllId] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);

  const { Option } = Select;
  const menuId = ReadCookie("menuId");
  const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
  const [orderStatusID, setOrderStatusID] = useState(ReadCookie(null));

  const [customerCode, setCustomerCode] = useState(null)
  const [salePersonID, setSalePersonID] = useState(null)
  const [customerTypeID, setCustomerTypeID] = useState(null)
  const [regionID, setRegionID] = useState(null)
  const [customerName, setCustomerName] = useState(null)
  const [paymentTerm, setPaymentTerm] = useState(null)
  const [shippingMethodID, setShippingMethodID] = useState(null)
  const [freightTerm, setFreightTerm] = useState(null)
  const [shippingAccount, setShippingAccount] = useState(null)
  const [resalecertificate, setResaleCertificate] = useState(null)
  const [email, setEmail] = useState(null)
  const [billAddress, setBillAddress] = useState(null)
  const [apfirstname, setApFirstName] = useState(null)
  const [aplastname, setApLastName] = useState(null)
  const [active, setActive] = useState(null)
  const [ishold, setIsHold] = useState(null)
  const [isApproved, setIsApproved] = useState(null)
  const [customerid, setCustomerid] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [excelData, setExcelData] = useState([])
  const [excel, setExcel] = useState([])

  useEffect(() => {
    dispatch(loadSalePerson(token));
    dispatch(getRegion(token));
    dispatch(getPaymentTerm(token));
    dispatch(getCustomerType(token));
    dispatch(loadFreightTerm(token));
    dispatch(loadShippingMethod(token));
    dispatch(loadBergenBillingOption(token));
    dispatch(getCustomerTerm(token));
    dispatch(getAllCompany(token));


    
    dispatch(loadOrderStatus(token)).then((res) => {
      setOrderStatusID(null)
    });
    dispatch(getAllCompany(token));
  }, []);

  const colTest = [
    {
      title: "Opening Date",
      dataIndex: "opening_date",
      key: "opening_date",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.opening_date || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus

            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.opening_date)
          return record.opening_date
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "opening_date" && sortedInfo.order
        : null,
      sorter: (a, b) => a.opening_date - b.opening_date,
    },
    {
      title: "Customer Code",
      dataIndex: "customer_code",
      key: "customer_code",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.customer_code || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus

            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.customer_code)
          return record.customer_code
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "customer_code" && sortedInfo.order
        : null,
      sorter: (a, b) => a.customer_code - b.customer_code,
    },

    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.customer_name || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.customer_name)
          return record.customer_name
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "customer_name" && sortedInfo.order
        : null,
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
    },
    {
      title: "Customer Type Name",
      dataIndex: "customer_type_name",
      key: "customer_type_name",
      width: 170,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo
        ? filteredInfo.customer_type_name || null
        : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.customer_type_name)
          return record.customer_type_name
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "customer_type_name" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.customer_type_name.localeCompare(b.customer_type_name),
    },
    {
      title: "Email Address",
      dataIndex: "email_address",
      key: "email_address",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.email_address || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.email_address)
          return record.email_address
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "email_address" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.email_address - b.email_address,
    },
    {
      title: "Term Name",
      dataIndex: "term_name",
      key: "term_name",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.term_name || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.term_name)
          return record.term_name
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "term_name" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.term_name - b.term_name,
    },
    {
      title: "Shipping Method Name",
      dataIndex: "shipping_method_name",
      key: "shipping_method_name",
      width: 200,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.shipping_method_name || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.shipping_method_name)
          return record.shipping_method_name
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "shipping_method_name" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.shipping_method_name - b.shipping_method_name,
    },
    {
      title: "Resale Certificate No",
      dataIndex: "resale_certificate_no",
      key: "resale_certificate_no",
      width: 180,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.resale_certificate_no || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.resale_certificate_no)
          return record.resale_certificate_no
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "resale_certificate_no" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.resale_certificate_no - b.resale_certificate_no,
    },
    {
      title: "Shipping Account No",
      dataIndex: "shipping_account_no",
      key: "shipping_account_no",
      width: 180,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.shipping_account_no || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.shipping_account_no)
          return record.shipping_account_no
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "shipping_account_no" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.shipping_account_no - b.shipping_account_no,
    },
    {
      title: "Order Status",
      dataIndex: "status_name",
      key: "status_name",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.status_name || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.status_name)
          return record.status_name
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "status_name" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.status_name - b.status_name,
    },
    {
      title: "Is Hold", dataIndex: "is_hold", key: "is_hold", width: 130,
      render: (text, row) => row.is_hold ? "True" : "False",
      filteredValue: filteredInfo ? filteredInfo.is_hold || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input

            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,

      onFilter: (value, record) => {
        if (record.is_hold !== undefined) return record.is_hold.toString().toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'is_hold' && sortedInfo.order : null,
      sorter: (a, b) => a.is_hold.localeCompare(b.is_hold),
    },
    {
      title: "Is Approved", dataIndex: "is_approved", key: "is_approved", width: 130,
      render: (text, row) => row.is_approved ? "True" : "False",
      filteredValue: filteredInfo ? filteredInfo.is_approved || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input

            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,

      onFilter: (value, record) => {
        if (record.is_approved !== undefined) return record.is_approved.toString().toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'is_approved' && sortedInfo.order : null,
      sorter: (a, b) => a.is_approved.localeCompare(b.is_approved),
    },
    {
      title: "Freight Term",
      dataIndex: "freight_term_name",
      key: "freight_term_name",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.freight_term_name || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.freight_term_name)
          return record.freight_term_name
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "freight_term_name" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.freight_term_name - b.freight_term_name,
    },
    {
      title: "Bill Address 1",
      dataIndex: "address_1",
      key: "address_1",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.address_1 || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            // placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.address_1)
          return record.address_1
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "address_1" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.address_1 - b.address_1,
    },
    {
      title: "Bill Address 2",
      dataIndex: "address_2",
      key: "address_2",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.address_2 || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            // placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.address_2)
          return record.address_2
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "address_2" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.address_2 - b.address_2,
    },
    {
      title: "Bill City",
      dataIndex: "city_name",
      key: "city_name",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.city_name || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            // placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.city_name)
          return record.city_name
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "city_name" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.city_name - b.city_name,
    },
    {
      title: "Bill State",
      dataIndex: "state_name",
      key: "state_name",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.state_name || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            // placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.state_name)
          return record.state_name
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "state_name" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.state_name - b.state_name,
    },
    {
      title: "Bill Country",
      dataIndex: "country_name",
      key: "country_name",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.country_name || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            // placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.country_name)
          return record.country_name
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "country_name" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.country_name - b.country_name,
    },
    {
      title: "Bill Zip",
      dataIndex: "zip_code",
      key: "zip_code",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_id +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.zip_code || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            // placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.zip_code)
          return record.zip_code
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "zip_code" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.zip_code - b.zip_code,
    },
    {
      title: "Active", dataIndex: "active", key: "active", width: 130,
      
      render: (text, row) => row.is_active  ? "True" : "False",
      filteredValue: filteredInfo ? filteredInfo.active || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input

            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,

      onFilter: (value, record) => {
        if (record.active !== undefined) return record.active.toString().toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'active' && sortedInfo.order : null,
      sorter: (a, b) => a.active.localeCompare(b.active),


    },
    {
      title: "AP First Name",
      dataIndex: "first_name",
      key: "first_name",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_code +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.first_name || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.first_name)
          return record.first_name
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "first_name" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.first_name - b.first_name,
    },
    {
      title: "AP Last Name",
      dataIndex: "last_name",
      key: "last_name",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_code +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.last_name || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.last_name)
          return record.last_name
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "last_name" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.last_name - b.last_name,
    },
    {
      title: "Sales Person",
      dataIndex: "sales_person_name",
      key: "sales_person_name",
      width: 130,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_code +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.sales_person_name || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.sales_person_name)
          return record.sales_person_name
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "sales_person_name" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.sales_person_name - b.sales_person_name,
    },
    {
      title: "Region ",
      dataIndex: "region_name",
      key: "region_name",
      width: 160,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_code +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.region_name || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.region_name)
          return record.region_name
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "region_name" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.region_name - b.region_name,
    },
    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_name",
      width: 90,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_code +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.gender_name || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.gender_name)
          return record.gender_name
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "gender_name" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.gender_name - b.gender_name,
    },
    {
      title: "Phone #",
      dataIndex: "phone_no",
      key: "phone_no",
      width: 90,
      render: (text, record) => (
        <Link
          to={
            "/customer-New-Info/edit/" +
            record.customer_code +
            "?edit=yes"
          }
        >
          {text}
        </Link>
      ),
      filteredValue: filteredInfo ? filteredInfo.phone_no || null : null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input

            autoFocus
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              borderRadius: "10px",
              width: "11em",
            }}
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.phone_no)
          return record.phone_no
            .toLowerCase()
            .includes(value.toLowerCase());
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "phone_no" && sortedInfo.order
        : null,
      sorter: (a, b) =>
        a.phone_no - b.phone_no,
    },
  ];
  const onSearch = (val) => {

  };
  const handleFilter = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setShow(true);
  };
  const onFinishFilter = (values) => {
    
    dispatch(loadCustomersList(token, customerCode, regionID, customerTypeID, salePersonID, paymentTerm, customerName,customerid, shippingMethodID, freightTerm, shippingAccount, resalecertificate, orderStatusID, email, billAddress, apfirstname, aplastname, active, ishold,phoneNumber, isApproved)).then((res) => {
      
      setDataLen(res?.payload?.data)
      downloadExcelFileData(res?.payload?.data);
    });
  };

  

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Opening Date": v.opening_date,
          "Customer Code": v.customer_code,
          "Customer Name": v.customer_name,
          "Customer Type Name": v.customer_type_name,
          "Email Address": v.email_address,
          "Term Name": v.term_name,
          "Shipping Method Name": v.shipping_method_name,
          "Resale Certificate No": v.resale_certificate_no,
          "Shipping Account No": v.shipping_account_no,
          "Order Status": v.status_name,
          "Is Hold": v.is_hold,
          "Is Approved": v.is_approved,
          "Freight Term": v.freight_term_name,
          "Bill Address 1": v.address_1,
          "Bill Address 2": v.address_2,
          "Bill City": v.city_name,
          "Bill State": v.state_name,
          "Bill Country": v.country_name,
          "Bill Zip": v.zip_code,
          "Active": v.is_active,
          "AP First Name": v.first_name,
          "AP Last Name": v.last_name,
          "Sales Person": v.sales_person_name,
          "Region": v.region_name,
          "Gender": v.gender_name,
          "Phone #": v.phone_no
        };
      })
    )
    setExcel(
      data.map((v) => {
        return {
          "Billing Customer Number": v.customer_code,
          "Billing Customer Alternate ID": null,
          "Billing Customer FEIN": v.resale_certificate_no,
          "Billing Customer Name 1": v.customer_name,
          "Billing Customer Name 2": null,
          "Billing Customer Attn Name": v.first_name +" "+ v.last_name,
          "Billing Customer Address 1": v.address_1,
          "Billing Customer Address 2": v.address_2,
          "Billing Customer City": v.city_name,
          "Billing Customer State": v.state_name,
          "Billing Customer Country": v.country_name,
          "Billing Customer Zip": v.zip_code,
          "Billing Customer Phone": v.phone_no,
          "Billing Customer Fax": null,
          "Billing Customer Email": v.email_address,
          "Billing Customer Contact": null,
          "Shipping Customer Number": null,
          "Shipping Customer Alternate ID": null,
          "Shipping Customer FEIN": null,
          "Shipping Customer Name 1": null,
          "Shipping Customer Name 2": null,
          "Shipping Customer Attn Name": null,
          "Shipping Customer Address 1": null,
          "Shipping Customer Address 2": null,
          "Shipping Customer City": null,
          "Shipping Customer State": null,
          "Shipping Customer Country": null,
          "Shipping Customer Zip": null,
          "Shipping Customer Phone": null,
          "Shipping Customer Fax": null,
          "Shipping Customer Email": null,
          "Shipping Customer Contact": null,
          "Last Transaction Date": null,
          "Terms": v.term_name,
        };
      })
    )
  } 

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader title={"Customer Information List"} />
        <div className="bodyStyle">

          <Collapse
            style={{ margin: "10px" }}
            bordered={false}
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          >
            <Panel header="Filters" key="1" className="site-collapse-custom-panel" extra={<FilterOutlined />}>
              <Form
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinishFilter}
                
                autoComplete="off"
              >
                <Row>
                  <Col xs={24} xl={8}>
                    <Form.Item
                      name="customer_code"
                      label="Customer Code"
                      value={customerCode}
                    >
                      <Input
                        className="abc"

                        onChange={(e) => setCustomerCode(e.target.value)}
                        
                        size="small" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="Sale Person" name="salePerson_id" initialValue={"All"}>
                      <Select
                        size="small"
                        showSearch
                        className="black_input"
                        
                        optionFilterProp="children"
                        loading={salePerson.loading}
                        onChange={(e) => setSalePersonID(e)}
                        filterSort={(optionA, optionB) =>
                          optionB?.value!=="All"?
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                      }
                      >
                        <Option value="All">All</Option>
                        {salePerson?.payload?.data.map((item) => {
                          return <Option defaultValue={"All"} key={item.sales_person_id} value={item?.sales_person_id}>{item?.salesperson_name}</Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="Region" name="region_id" initialValue={"All"}>
                      <Select
                        size="small"
                        showSearch
                        onChange={(e) => setRegionID(e)}

                        
                        optionFilterProp="children"
                        loading={region.loading}
                        filterSort={(optionA, optionB) =>
                          optionB?.value!=="All"?
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                      }
                      >
                        <Option value="All">All</Option>
                        {region?.payload?.data.map((item) => {
                          return <Option defaultValue={"All"} key={item.region_id} value={item?.region_id}>{item?.region_name}</Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} xl={8}>
                    <Form.Item label="Customer" name="customer_name">
                      <Input

                        
                        size="small" onChange={(e) => setCustomerName(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="Customer Type" name="customer_type_id" initialValue={"All"}>
                      <Select
                        size="small"
                        onChange={(e) => setCustomerTypeID(e)}

                        showSearch
                        
                        optionFilterProp="children"
                        loading={customerTypes.loading}
                        filterSort={(optionA, optionB) =>
                          optionB?.value!=="All"?
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                      }
                      >
                        <Option value="All">All</Option>
                        {customerTypes?.payload?.data.map((item) => {
                          return <Option defaultValue={"All"} key={item.customer_type_id} value={item?.customer_type_id}>{item?.customer_type_name}</Option>
                        })
                        }

                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="Term" name="payment_term_id" initialValue={"All"}>
                      <Select

                        onChange={(e) => setPaymentTerm(e)}
                        size="small"
                        showSearch
                        loading={paymentterm.loading}
                        
                        optionFilterProp="children"
                        filterSort={(optionA, optionB) =>
                          optionB?.value!=="All"?
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                      }
                      >
                        <Option value="All">All</Option>
                        {paymentterm?.payload?.data.map((item) => {
                          return <Option defaultValue={"All"} key={item.term_id} value={item?.term_id}>{item?.term_name}</Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} xl={8}>
                    <Form.Item label="Shipping Account" name="shipping_account_no" value={shippingAccount}>
                      <Input

                        
                        size="small" onChange={(e) => setShippingAccount(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="Shipping Method" name="shippingMethod" initialValue={"All"}>
                      <Select
                        size="small"
                        onChange={(e) => setShippingMethodID(e)}

                        showSearch
                        onSearch={onSearch}
                        
                        optionFilterProp="children"
                        loading={ShippingMethod.loading}
                        filterSort={(optionA, optionB) =>
                          optionB?.value!=="All"?
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                      }
                      >
                        <Option value="All">All</Option>
                        {ShippingMethod?.payload?.data.map(
                          (shipMethod) => {
                            return (
                              <Option defaultValue={"All"} value={shipMethod.shipping_method_id}>
                                {shipMethod.shipping_method_name}
                              </Option>
                            );
                          }
                        )}

                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="Freight Term:" name="freightTerm" initialValue={"All"}>
                      <Select

                        onChange={(e) => setFreightTerm(e)}
                        size="small"
                        showSearch
                        onSearch={onSearch} filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        loading={FreightTerm.loading}
                        
                        optionFilterProp="children"
                        filterSort={(optionA, optionB) =>
                          optionB?.value!=="All"?
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                      }
                      >
                        <Option value="All">All</Option>
                        {FreightTerm?.payload?.data.map(
                          (freight) => {
                            return (
                              <Option defaultValue={"All"} value={freight.freight_term_id}>
                                {freight.freight_term_name}
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} xl={8}>
                    <Form.Item label="Resale Certificate" name="resalecertificate" value={resalecertificate}>
                      <Input

                        
                        size="small" onChange={(e) => setResaleCertificate(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="Order Status" name="orderStatus"
                      value={orderStatusID}
                      initialValue={"All"}
                    >
                      <Select
                        size="small"
                        onChange={(e) => setOrderStatusID(e)}

                        showSearch
                        onSearch={onSearch}
                        
                        optionFilterProp="children"
                        loading={customerSections.loading}
                        filterSort={(optionA, optionB) =>
                          optionB?.value!=="All"?
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                      }
                      >
                        <Option value="All">All</Option>
                        {customerSections?.payload?.data.map(
                          (order) => {
                            return (
                              <Option defaultValue={"All"} value={order.order_status_id}>
                                {order.status_name}
                              </Option>
                            );
                          }
                        )}

                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="Email" name="email" value={email}>
                      <Input

                        
                        size="small" onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} xl={8}>
                    <Form.Item label="BillAddress" name="billAddress" value={billAddress}>
                      <Input

                        
                        size="small" onChange={(e) => setBillAddress(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="AP First Name" name="apfirstname" value={apfirstname}>
                      <Input

                        
                        size="small" onChange={(e) => setApFirstName(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="AP Last Name" name="aplastname" value={aplastname}>
                      <Input

                        
                        size="small" onChange={(e) => setApLastName(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} xl={8}>
                    {/* <Form.Item label="Active" name="active" value={active}>
                      <Input
                        
                        
                        size="small" onChange={(e) => setActive(e.target.value)}
                      />
                    </Form.Item> */}

                    <Form.Item
                      name="active"
                      label="Active"
                      value={active}
                    
                    
                    >
                      <Select

                        size="small"
                        tabIndex={26}
                        autoComplete={"nope"}
                        onChange={(value) => setActive(value)}
                        filterSort={(optionA, optionB) =>
                          optionB?.value!==null?
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                      }
                      >
                        <Option key={3} value={null}>
                          All
                        </Option>
                        <Option key={1} value={"True"}>
                          True
                        </Option>
                        <Option key={2} value={"False"}>
                          False
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} xl={8}>
                    <Form.Item label="Is Hold" name="ishold" value={ishold}>
                      {/* <Input

                        size="small" onChange={(e) => setIsHold(e.target.value)}
                      /> */}
                      <Select

                          size="small"
                          tabIndex={26}
                          autoComplete={"nope"}
                          onChange={(value) => setIsHold(value)}
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                          >
                          <Option key={3} value={null}>
                            All
                          </Option>
                          <Option key={1} value={"True"}>
                            True
                          </Option>
                          <Option key={2} value={"False"}>
                            False
                          </Option>
                          </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} xl={8}>
                    <Form.Item label="Customer Id" name="customerid" value={customerid}>
                      <Input

                        
                        size="small" onChange={(e) => setCustomerid(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="Phone #" name="phoneNumber" value={phoneNumber}>
                      <Input

                        
                        size="small" onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item label="Is Approved" name="isApproved" value={isApproved}>
                      {/* <Input

                        size="small" onChange={(e) => setIsHold(e.target.value)}
                      /> */}
                      <Select

                          size="small"
                          tabIndex={26}
                          autoComplete={"nope"}
                          onChange={(value) => setIsApproved(value)}
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                          >
                          <Option key={3} value={null}>
                            All
                          </Option>
                          <Option key={1} value={"True"}>
                            True
                          </Option>
                          <Option key={2} value={"False"}>
                            False
                          </Option>
                          </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                </div>
              </Form>
            </Panel>
          </Collapse>

          {customerList.loading ? (
            <div className="mainProductContainer">
              <SkeletonLoad />
            </div>
          ) : (
            <div className="mainProductContainer">
              <div
                style={{ padding: 10 }}
              
              >
                    <CSVLink
                    style={{padding: 10}}
                      filename={"Customer-Information.csv"}
                      data={excelData}
                      onClick={() => {
                      }}
                    >
                      Download Excel
                    </CSVLink>
                    <div style={{ paddingTop: "10px", paddingBottom: "10px"}}>
                    <CSVLink
                    style={{padding: 10}}
                      filename={"Avalara_Customer_Fields.csv"}
                      data={excel}
                      onClick={() => {
                      }}
                    >
                       Avalara Customer Fields
                    </CSVLink>
                    </div>
                <Table
                  dataLen={dataLen}
                  setDataLen={setDataLen}
                  show={show}
                  handleFilter={handleFilter}
                  type="Customer"
                  customerAllId={setCustomerAllId}
                  
                  
                  perPage={25}
                  scroll={{
                    x: 'calc(400px + 80%)',
                    y: 450,
                  }}
                  columns={colTest}
                  dataSource={customerList?.payload?.data}
                  loading={customerList.loading}
                  summary={(pageData) => {
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={3} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Number of Record : 
                             { customerList?.payload?.data.length}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>

                      </>
                    );
                  }
                  }
                  pagination={{
                    current: 1,
                    defaultPageSize: 100,
                    pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                  }}
                />
                {/* <span className="records-text">
                      {dataLen === 0 ? <>No of Records:  <span className="fw-500">  0</span> </> : < > No of Records: <span className="fw-500"> {show ? dataLen : dataLen ? dataLen : "0"}</span>  </>}
                    </span> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
