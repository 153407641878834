import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import {
  Button,
  Form,
  DatePicker,
  Table, Spin, Input
} from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { getcontrolSheetLog } from "../../redux/actions/controlSheetLog.action";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ProductImage from "../../components/Form/ProductImage";

const ControlSheetLog = () => {
  TabTitle("Control Sheet Log")
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const dispatch = useDispatch();
  const token = ReadCookie("token");
  const [controlSheet, setControlSheet] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const { controlLog } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([])
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'PT #',
      dataIndex: 'ship_header_no',
      key: 'ship_header_no',
      render: (text, record) => (
        <Link style={{ color: "#0d6efd", textDecoration: "underline" }}
          to={"/order-shipment?mode=edit&order_id=" + record.ship_header_id + "&customer_id/" + record.customer_id + "?edit=yes"}
        >
          {text}
        </Link>
      ),
    },
    {
      title: 'Log Date',
      render: (text, record) => {
        return <>{moment(record.log_date).utcOffset(0).format('YYYY-MM-DD HH:mm')}</>;
      },
    },
    {
      title: 'PT Date',
      render: (text, record) => {
        return <>{moment(record.ship_date).format("MM-DD-YYYY")}</>;
      },
    },
    {
      title: 'Start Date',
      render: (text, record) => {
        return <>{moment(record.start_date).format("MM-DD-YYYY")}</>;
      },
    },
    {
      title: 'Cancel Date',
      render: (text, record) => {
        return <>{moment(record.cancel_date).format("MM-DD-YYYY")}</>;
      },
    },
    {
      title: 'Customer',
      dataIndex: 'addressee',
      key: 'addressee',
    },
    {
      title: 'SO #',
      dataIndex: 'order_header_no',
      key: 'order_header_no',
    },
    {
      title: 'PO #',
      dataIndex: 'reference_no',
      key: 'reference_no',
    },
    {
      title: 'Qty',
      render: (text, record) => {
        return <>{parseFloat(record.quantity).toFixed(0)}</>;
      },
      align: "right",
    },
    {
      title: 'Amount',
      render: (text, record) => {
        return <>$ {numeral(record.gross_amount).format("0,00")}</>
      },
      align: "right",
    },
    {
      title: 'Freight',
      render: (text, record) => {
        return <>$ {numeral(record.shipping_cost).format("0,00")}</>
      },
      align: "right",
    },
    {
      title: 'Net Amount',
      render: (text, record) => {
        return <>$ {numeral(record.net_amount).format("0,00")}</>
      },
      align: "right",
    },
    {
      title: 'Term',
      dataIndex: 'term_name',
      key: 'term_name',
    },
    {
      title: 'Sale Person',
      dataIndex: 'salesperson_name',
      key: 'salesperson_name',
    },
    {
      title: 'Region',
      dataIndex: 'region_name',
      key: 'region_name',
    },
    {
      title: 'PT Status',
      dataIndex: 'pt_status_name',
      key: 'pt_status_name',
    },
    {
      title: 'Approved',
      dataIndex: 'is_approved',
      key: 'is_approved',
      render: (text, record) => {
        return <>{record.is_approved ? "Yes" : "No"}</>
      },
    },
    {
      title: 'Customer Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Customer Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Comment',
      dataIndex: 'cs_comment',
      key: 'cs_comment',
    },
  ];

  const onFinish = async () => {
    setLoading(true);
    await form.validateFields().then((values) => {
      dispatch(getcontrolSheetLog(token, values)).then((res) => {
        setLoading(false);
        setControlSheet(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }).catch((error) => {
      setLoading(false);
    })
  }

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "PT #": v.ship_header_no,
          "Log Date": moment(v.log_date).format("MM-DD-YYYY"),
          "PT Date": moment(v.ship_date).format("MM-DD-YYYY"),
          "Start Date": moment(v.start_date).format("MM-DD-YYYY"),
          "Cancel Date": moment(v.cancel_date).format("MM-DD-YYYY"),
          "Customer": v.addressee,
          "SO #": v.order_header_no,
          "PO #": v.reference_no,
          "Qty": v.quantity,
          "Amount": v.gross_amount,
          "Freight": v.shipping_cost,
          "Net Amount": v.net_amount,
          "Term": v.term_name,
          "Sale Person": v.salesperson_name,
          "Region": v.region_name,
          "PT Status": v.pt_status_name,
          "Approved": v.is_approved ? "Yes" : "No",
          "Customer Email": v.email,
          "Customer Phone": v.phone,
          "Comment": v.cs_comment,
        };
      })
    )
  }

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <ProductImage />
        <PageHeader
          title={"Control Sheet Log"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-0">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            name="pt_no"
                            label="PT #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div className="relative">
                          <Form.Item
                            name="log_date"
                            label="Log Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm "
                onClick={onFinish}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {
          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            controlSheet.length > 0 ? (
              <Table
                title={() =>
                  <CSVLink
                    filename={"Control-Sheet-Log.csv"}
                    data={excelData}
                    onClick={() => {

                    }}
                  >
                    Download Excel
                  </CSVLink>
                }
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                perPage={15}
                scroll={{ x: 'max-content' }}
                dataSource={controlSheet}
                columns={columns}
                loading={controlLog.loading}
                
                


                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 100,
                  pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                }}
              />
            ) : <div
              style={{
                backgroundColor: "#CCCCCC",
                borderWidth: "1pt",
                borderStyle: "Dashed",
                fontWeight: "bold",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No record(s)
            </div>
          )
        }
      </div>
    </div>
  )
}

export default ControlSheetLog;
