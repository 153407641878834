import React, { useState, useEffect } from "react";
import "./login.css";
import { Form, Input, Divider, Row, Col, Checkbox } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { userLogin } from "../../redux/actions/auth";
import notification from "../../utils/notification";
import { getDefaultCompany } from "../../redux/actions/defaultCompany";
import logo from "../../assets/images/logo-white.svg";
import { Button, Modal } from "antd";
import { fetchIpWithoutApi } from "../../utils/fetchIP";
import { Link } from 'react-router-dom';
import LoginImage from "../../assets/images/dl1961img.webp"

const Login = ({ setModalValue }) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const { auth } = useSelector((state) => state);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disable, setDisable] = useState(false);

  const handleLogin = async () => {

    dispatch(userLogin(email, password)).then(async (res) => {
      if (res.type === "ADMIN_LOGIN_ERROR") {
        notification("error", "Login Error", res.payload.data.data);
      }
      if (res.type === "ADMIN_LOGIN_SUCCESS") {

        setModalValue(res.payload?.is_first_login);
        localStorage.setItem(
          "customer_type_id",
          ` ${res?.payload?.customer_type_id
            ? res?.payload?.customer_type_id
            : null
          }`
        );
        localStorage.setItem(
          "gender_id",
          ` ${res?.payload?.gender_id ? res?.payload?.gender_id : null}`
        );

        await dispatch(getDefaultCompany(res.payload.accessToken)).then(
          (res) => {
            if (res.type === "GET_DEFAULT_COMPANY_SUCCESS") {

              document.cookie = `defaultCompany=${res.payload.company_id}`;
              document.cookie = `defaultCompanyName=${res.payload.company_name}`;
            }
          }
        );
        notification("success", "Login Success", "Login Successfully");
      }
      setPassword("");
    });
  };

  useEffect(() => {
    if (email === "" || password === "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
    return () => {
      setDisable(false);
    };
  }, [email, password]);

  return (

    <>
      <Row style={{ height: '100vh' }}>
        <Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12} style={{ backgroundImage: `url(${LoginImage})`, backgroundSize: 'cover' }}>
          <div style={{ height: "100vh", width: "100%", backgroundColor: "rgba(60,75,88,0.7)", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div className="logo-login">
              <Link to="/dashboard"><img src={logo} alt="Logo" /></Link>
            </div>
            <div className="login-left-hd">
              Welcome back to DL1961
            </div>
            <div className="login-left-para">
              DL1961 is a New York based brand on a mission to do things differently. We're changing the way denim is made to lessen our impact on the planet for future.
            </div>
            <div className="login-left-bottom">
              Design By <a style={{ color: "white" }} href="https://www.fascom.com/" target="_blank">Fascom Limited</a>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className="login-form-wrapper-main">

            <div className="login-form-wrapper">

              <div className="login-hd">Sign in </div>

              <Form className="ant-login-form" onFinish={() => handleLogin()}>
                <Input
                  autoFocus

                  className="login-input"
                  size="large"
                  placeholder="Username/Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  className="login-input"
                  // style={{ marginTop: 10 }}
                  size="large"
                  type={"password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  className="login-btn-main"
                  loading={auth.loading}
                  disabled={disable}
                  htmlType="submit"
                  type="primary"
                  block
                >
                  Login
                </Button>
                <Button className="forgotPassword" type="link" block>
                  Forget your password?
                </Button>
                <p className="login-form-text">
                  By clicking on the Log In button, you understand and agree to{" "}
                  <span className="links">
                    <a href="#"> OMS Terms of Use</a>
                  </span>{" "}
                  and{" "}
                  <span className="links">
                    <a href="#"> OMS Privacy Policy</a>
                  </span>
                </p>
              </Form>
            </div>
          </div>

        </Col>
      </Row>
    </>
  );
};
export default Login;
